/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useState } from 'react';
import { useConfig } from 'context';
import { RedirectData } from '../api';

const IFRAME_TARGET_ID = 'PROVIDER_IFRAME';

const createFieldComponents = (parameters: object) => {
  return Object.entries(parameters).map(([param, paramValue]) => {
    return <input key={param} type="hidden" name={param} value={paramValue} />;
  });
};

export const Redirect = ({
  container,
  html,
  method,
  parameters,
  url,
}: RedirectData) => {
  const { openWindow } = useConfig();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    // This is for container: redirect/window and GET
    if (['redirect', 'window'].includes(container) && method === 'GET' && url) {
      openWindow(url, { replaceCurrentWindow: false });
    }
  }, [openWindow, container, parameters, url, method]);

  const handleFormSubmit = useCallback(
    (node: HTMLFormElement | null) => {
      // This is for container: redirect/window and POST
      if (node && method === 'POST' && !hasSubmitted) {
        node.submit();
        setHasSubmitted(true);
      }
    },
    [method, hasSubmitted]
  );

  return (
    <>
      {container !== 'iframe' && method === 'POST' && (
        <form
          ref={(node) => handleFormSubmit(node)}
          action={url}
          method="post"
          target="_blank" // this will open it in a new window
        >
          {parameters && createFieldComponents(parameters)}
        </form>
      )}

      {container === 'iframe' && (
        <>
          {method === 'POST' && url && (
            <form
              ref={(node) => handleFormSubmit(node)}
              action={url}
              method="post"
              target={IFRAME_TARGET_ID}
              css={{ visibility: 'hidden' }}
            >
              {parameters && createFieldComponents(parameters)}
            </form>
          )}
          <iframe
            name={IFRAME_TARGET_ID}
            srcDoc={html}
            src={method === 'GET' ? url : undefined}
            css={{
              border: 'none',
              position: 'fixed',
              inset: 0,
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              overflow: 'hidden',
              zIndex: 999999,
              backgroundColor: 'white',
            }}
          />
        </>
      )}
    </>
  );
};
