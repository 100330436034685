/** @jsxImportSource @emotion/react */
import {
  Button,
  ButtonProps,
  createStyles,
  IconName,
} from '@quickbit/qb-design-system';
import { ReactNode, useRef, useEffect, useState } from 'react';
import { Header } from 'components';

export interface Props {
  children: ReactNode;
  title?: string;
  breadcrumb?: string;
  actions?: {
    icon: IconName;
    title: string;
    content: ReactNode;
  }[];
  progress?: number;
  primaryButtonProps?: Omit<ButtonProps, 'variant'>;
  secondaryButtonProps?: Omit<ButtonProps, 'variant'>;
  textButtonProps?: Omit<ButtonProps, 'variant'>;
  onBack?: () => void;
}

export const Layout = ({
  children,
  title,
  breadcrumb,
  actions,
  progress,
  primaryButtonProps,
  secondaryButtonProps,
  textButtonProps,
  onBack,
}: Props) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isInModal, setIsInModal] = useState(false);

  useEffect(() => {
    if (ref.current) {
      if (ref.current.parentElement) {
        if ('id' in ref.current.parentElement) {
          setIsInModal(ref.current.parentElement.id == 'MODAL');
        }
      }
    }
  }, [ref]);

  return (
    <div
      data-testid="layout-wrapper"
      css={[styles.wrapper, !isInModal && styles.largeWrapper]}
      ref={ref}
    >
      {title && (
        <Header
          title={title}
          actions={actions}
          onBack={onBack}
          breadcrumb={breadcrumb}
          progress={progress}
          isInModal={isInModal}
        />
      )}

      <main css={[styles.container, !isInModal && styles.largeContainer]}>
        {children}
      </main>
      {(primaryButtonProps || secondaryButtonProps || textButtonProps) && (
        <section css={styles.footer}>
          <div css={styles.buttonContainer}>
            {primaryButtonProps && (
              <Button
                variant="primary"
                text={primaryButtonProps.text}
                icon={primaryButtonProps.icon}
                loading={primaryButtonProps.loading}
                disabled={primaryButtonProps.disabled}
                onClick={primaryButtonProps.onClick}
                testId="primary-button"
              />
            )}
            {secondaryButtonProps && (
              <>
                <div css={styles.buttonSeparator} />
                <Button
                  variant="secondary"
                  text={secondaryButtonProps.text}
                  icon={secondaryButtonProps.icon}
                  loading={secondaryButtonProps.loading}
                  disabled={secondaryButtonProps.disabled}
                  onClick={secondaryButtonProps.onClick}
                  testId="secondary-button"
                />
              </>
            )}
            {textButtonProps && (
              <>
                <div css={styles.buttonSeparator} />
                <Button
                  variant="text"
                  text={textButtonProps.text}
                  icon={textButtonProps.icon}
                  loading={textButtonProps.loading}
                  disabled={textButtonProps.disabled}
                  onClick={textButtonProps.onClick}
                  testId="text-button"
                />
              </>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

const useStyles = createStyles(({ spacing, sizing, breakpoints }) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  largeWrapper: {
    [breakpoints.laptopSmall]: {
      maxWidth: '100%',
    },
  },
  container: {
    flex: 1,
    margin: `0 ${spacing.xxs}`,
    overflowY: 'auto',
  },
  largeContainer: {
    [breakpoints.laptopSmall]: {
      margin: 0,
    },
  },
  buttonSeparator: {
    marginTop: spacing.xxs,
  },
  buttonContainer: {
    maxWidth: sizing.medium,
    width: '100%',
    margin: `${spacing.xxs} 0`,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: `${spacing.xxxs} ${spacing.xxs}`,
    margin: 'auto',
    marginTop: spacing.xxxs,
    [breakpoints.laptopSmall]: {
      padding: `${spacing.s} ${spacing.xxs}`,
    },
  },
}));
