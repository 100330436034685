/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import { createStyles } from '@quickbit/qb-design-system';
import { useAsync } from 'react-use';
import { getImage, ImageKey } from '../api/contentful/getImage';

export interface ImageProps {
  name: ImageKey;
  size?: string;
  showPlaceholder?: boolean;
}

export const Image = ({
  name,
  size = '149px',
  showPlaceholder = true,
}: ImageProps) => {
  const styles = useStyle();
  const { value, loading } = useAsync(() => getImage(name), [name]);

  if (loading || !value?.file) {
    if (showPlaceholder) {
      return (
        <img
          alt={'Loading'}
          css={[styles.imgSkeleton, { width: size, height: size }]}
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        />
      );
    } else {
      return <div css={[{ width: size, height: size }]} />;
    }
  }

  return <img css={{ width: size }} src={value.file.url} alt={value.key} />;
};

const useStyle = createStyles(({ palette, shape }) => ({
  imgSkeleton: {
    animation: `${keyframes`
    0% {
      background-color: ${palette.grey.x300};
    }
    100% {
      background-color: ${palette.grey.x50};
    }
    `} 0.75s linear infinite alternate`,
    borderRadius: shape.borderRadius,
  },
}));
