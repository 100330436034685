import { init as sentryInit } from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
  DeepLinkProvider,
  KycProvider,
  SessionProvider,
  MixpanelProvider,
} from 'context';
import { getConfig } from 'utils';
import { SENTRY_OPTIONS } from 'utils/getSentryOptions';
import App from './App';

// Initialize Sentry
sentryInit(SENTRY_OPTIONS);

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <SessionProvider>
      <DeepLinkProvider>
        <KycProvider>
          <MixpanelProvider name="global-app-mixpanel-instance">
            <App config={getConfig()} />
          </MixpanelProvider>
        </KycProvider>
      </DeepLinkProvider>
    </SessionProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
