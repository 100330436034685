/** @jsxImportSource @emotion/react */
import _PaymentIQCashier, {
  IPiqCashierApiMethods,
} from 'paymentiq-cashier-bootstrapper';
import { useRef } from 'react';
import { useMount, useAsyncFn, useUpdateEffect } from 'react-use';
import { ErrorComponent } from 'components';
import { fetchPIQCashierData, handlePiqFailure } from './api';
import { useDefaultCSS, useDefaultConfig } from './hooks';

interface Props {
  previewId: string;
  product: string;
  qbMerchantId?: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (data: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancelled?: (data: any) => void;
  onCashierDataFailed?: () => void;
}

export const PaymentIQIFrame = ({
  previewId,
  product,
  qbMerchantId,
  onSuccess,
  onError,
  onCancelled,
  onCashierDataFailed,
}: Props) => {
  const defaultCSS = useDefaultCSS();
  const defaultConfig = useDefaultConfig();
  const cashierRef = useRef<HTMLDivElement | null>(null);

  const [{ value, error, loading }, getPIQCashierData] = useAsyncFn(
    () => fetchPIQCashierData(previewId),
    [previewId]
  );

  const [, handleFailure] = useAsyncFn(
    (failureData) => {
      const { data } = failureData; // eslint-disable-line @typescript-eslint/no-explicit-any
      return handlePiqFailure({ data, previewId });
    },
    [previewId]
  );

  useMount(() => getPIQCashierData());

  useUpdateEffect(() => {
    if (!cashierRef.current?.innerHTML) {
      new _PaymentIQCashier(
        '#cashier',
        {
          ...defaultConfig,
          environment,
          merchantId,
          userId: userId,
          sessionId: sessionId,
          amount: parseFloat(amount).toFixed(2),
          attributes: {
            preview_id: previewId,
            product: product,
            qb_merchant_id: qbMerchantId,
          },
          showReceipt,
          storeAccount,
          saveAccountOption,
        },
        (api: IPiqCashierApiMethods) => {
          api.css(defaultCSS);

          api.on({
            cashierInitLoad: () => console.log('Cashier init load'),
            success: onSuccess
              ? (data) => {
                  onSuccess(data);
                }
              : undefined,
            failure: (errorData) => {
              handleFailure(errorData);
              console.error(errorData);
              if (onError) onError(errorData);
            },
            cancelled: onCancelled ?? ((errorData) => console.error(errorData)),
            isLoading: (data) => console.log('Data is loading', data),
          });
        }
      );
    }
  }, [value]);

  useUpdateEffect(() => {
    if (error && error.statusCode === '404' && onCashierDataFailed)
      onCashierDataFailed();
  }, [error]);

  if (error) {
    return <ErrorComponent error={error} tryAgainAction={getPIQCashierData} />;
  }

  if (loading || !value) return null;

  const {
    userId,
    sessionId,
    amount,
    showReceipt,
    environment,
    merchantId,
    storeAccount,
    saveAccountOption,
  } = value;

  return (
    <div
      id="cashier"
      ref={cashierRef}
      style={{ height: '100%', width: '100%' }}
    />
  );
};
