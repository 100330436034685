import { fetchAPI } from 'api';
import { DestinationResponse } from 'pages/Wallet/types';
import { MoneyScaled } from 'types';

export interface SendPreviewResponseBase {
  id: string;
  destination: DestinationResponse;
  amount: MoneyScaled;
}

export type CreateSendPreviewResponse = SendPreviewResponseBase & {
  destinationAddress: string;
  walletId: string;
  createdAt: Date;
  amountReference: MoneyScaled;
  serviceFee: MoneyScaled;
  totalReference: MoneyScaled;
  estimatedNetworkFee: MoneyScaled;
  estimatedTotal: MoneyScaled;
};

export const createSendPreview = async (receiveOrderPreviewId: string) => {
  return await fetchAPI<CreateSendPreviewResponse>('send/previews', {
    method: 'POST',
    body: {
      receiveOrderPreviewId,
    },
  });
};

export const cancelOrder = async (receiveOrderPreviewId: string) =>
  await fetchAPI<{ success: boolean }>(
    `receive/orders/${receiveOrderPreviewId}/cancel`,
    {
      method: 'PUT',
    }
  );
