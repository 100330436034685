import { type IconName } from '@quickbit/qb-design-system';
import { type TansactionType } from '../api';

export type TransactionRecord = Record<
  TansactionType,
  { displayTextId: string; icon: IconName }
>;

export const transactionRecord: TransactionRecord = {
  BUY: { displayTextId: 'bought', icon: 'plus' },
  SEND_INTERNAL: { displayTextId: 'sent', icon: 'send' },
  SEND_EXTERNAL: { displayTextId: 'sent', icon: 'send' },
  RECEIVE_INTERNAL: { displayTextId: 'received', icon: 'send' },
  RECEIVE_EXTERNAL: { displayTextId: 'received', icon: 'send' },
  SELL: { displayTextId: 'sold', icon: 'minus' },
  SWAP_SOURCE: { displayTextId: 'swapped', icon: 'swap' },
  SWAP_DESTINATION: { displayTextId: 'swapped', icon: 'swap' },
  REFUND: { displayTextId: 'refunded', icon: 'send' },
  SEND_FIAT_INTERNAL: { displayTextId: 'sent', icon: 'send' },
  SEND_FIAT_EXTERNAL: { displayTextId: 'sent', icon: 'send' },
  RECEIVE_FIAT_INTERNAL: { displayTextId: 'received', icon: 'send' },
  RECEIVE_FIAT_EXTERNAL: { displayTextId: 'received', icon: 'send' },
  CARD_PAYMENT: { displayTextId: 'card_payment', icon: 'payment-method-card' },
  INTERNAL_TRANSFER_SOURCE: { displayTextId: 'transferred', icon: 'send' },
  INTERNAL_TRANSFER_DESTINATION: { displayTextId: 'transferred', icon: 'send' },
  YIELD_PAYOUT: { displayTextId: 'yield_payout', icon: 'earn' },
  GIVEAWAY: { displayTextId: 'giveaway', icon: 'plus' },
};

type EuroWalletStatus = Record<string, { titleId: string; subtitleId: string }>;

export const euroWalletStatus: EuroWalletStatus = {
  ACTIVE: { titleId: 'page.sell.sellTo', subtitleId: 'page.sell.euroAccount' },
  CONSENT_REQUIRED: {
    titleId: 'page.sell.euroAccount',
    subtitleId: 'page.sell.reconnectEuroAccount',
  },
  ACTIVATION_REQUIRED: {
    titleId: 'page.sell.euroAccount',
    subtitleId: 'page.sell.activateEuroAccount',
  },
  PENDING: {
    titleId: 'page.sell.euroAccount',
    subtitleId: 'page.sell.pendingApproval',
  },
};
