/** @jsxImportSource @emotion/react */
import {
  IconName,
  Navbar,
  NavbarItem,
  useTheme,
} from '@quickbit/qb-design-system';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBanner } from 'components';
import { useKycData } from 'context';

interface Props {
  children: ReactNode;
}

export const NavbarLayout = ({ children }: Props) => {
  const { breakpoints, sizing, spacing } = useTheme();
  const tabs: { name: string; icon: IconName; path: string }[] = [
    { name: 'Home', icon: 'home', path: '/' },
    { name: 'Profile', icon: 'profile', path: '/profile' },
  ];
  const kyc = useKycData();

  const showNotifBadge = !!(
    kyc &&
    (kyc.accountStatus === 'FROZEN' ||
      kyc.actions.some((value) => value.status !== 'APPROVED'))
  );

  const realTabs = tabs.map(({ name, icon, path }) => (
    <NavLink key={path} to={path} style={{ textDecoration: 'none' }}>
      {({ isActive }: { isActive: boolean }) => (
        <div css={{ position: 'relative' }}>
          <NavbarItem
            icon={icon}
            name={name}
            isActive={isActive}
            showNotificationBadge={name === 'Profile' && showNotifBadge}
          />
        </div>
      )}
    </NavLink>
  ));

  return (
    <Navbar tabs={realTabs}>
      <div css={{ display: 'flex', gap: spacing.xxs }}>
        <div
          css={{
            width: '100%',
            [breakpoints.laptopSmall]: {
              width: sizing.large,
              maxWidth: sizing.large,
            },
          }}
        >
          {children}
        </div>
        <AppBanner />
      </div>
    </Navbar>
  );
};
