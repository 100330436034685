/** @jsxImportSource @emotion/react */
import {
  getCurrencyName,
  IconName,
  createStyles,
  List,
  ListItem,
  Tag,
  Icon,
  useTheme,
} from '@quickbit/qb-design-system';
import { isEqual } from 'lodash';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useFormatCurrency, useFormatChange } from 'hooks';
import { euroWalletStatus } from 'pages/Wallet/constants';
import { FiatWallet, Wallet } from 'types';
import { isFeatureFlagActive, FEATURE_FLAG } from 'utils';

interface Props {
  wallets: Wallet[];
  showCurrency?: boolean;
  handleOnClick?: (wallet?: Wallet) => void;
  setShowActivateEuro?: () => void;
  testId?: string;
}

export const WalletList = ({
  wallets,
  showCurrency,
  handleOnClick,
  setShowActivateEuro,
  testId,
}: Props) => {
  const formatCurrency = useFormatCurrency();
  const formatChange = useFormatChange();
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const { spacing } = useTheme();
  const euroWallet = wallets.find(
    (w) => w.currencyCode === 'EUR'
  ) as FiatWallet;
  const navigate = useNavigate();

  const euroAccountStatus = euroWallet && euroWalletStatus[euroWallet.status];
  const showEuroAccount =
    isFeatureFlagActive(FEATURE_FLAG.ENABLE_EURO_ACCOUNT_CREATION) &&
    !(euroWallet && euroWallet.status === 'ACTIVE') &&
    setShowActivateEuro;

  return (
    <div css={styles.walletList}>
      <List>
        {wallets
          .filter(
            (w) =>
              w.currencyCode !== 'EUR' || (w as FiatWallet).status === 'ACTIVE'
          )
          .map((wallet) => (
            <ListItem
              key={wallet.id}
              testId={`${wallet.currencyCode}-${testId || 'wallet-list-item'}`}
              icon={wallet.currencyCode as IconName}
              title={getCurrencyName(wallet.currencyCode)}
              subtitle={
                showCurrency ? (
                  wallet.currencyCode
                ) : wallet.changeReference ? (
                  <div
                    css={[
                      {
                        ...(Number(
                          wallet.changeReference.pricePercentageChange24H
                        ) < 0
                          ? styles.loss
                          : {}),
                      },
                    ]}
                  >
                    <Tag
                      variant={
                        Number(
                          wallet.changeReference.pricePercentageChange24H
                        ) < 0
                          ? 'danger'
                          : 'success'
                      }
                      value={`${formatChange(
                        wallet.changeReference.pricePercentageChange24H
                      )}%`}
                      iconName="arrow"
                    />
                  </div>
                ) : (
                  <></>
                )
              }
              content={
                showCurrency && wallet.changeReference
                  ? formatCurrency(wallet.changeReference.currentPrice)
                  : formatCurrency(wallet.balanceReference)
              }
              subcontent={
                showCurrency && wallet.changeReference ? (
                  <div
                    css={[
                      styles.subContent,
                      {
                        ...(Number(
                          wallet.changeReference.pricePercentageChange24H
                        ) < 0
                          ? styles.loss
                          : {}),
                      },
                    ]}
                  >
                    <Tag
                      variant={
                        Number(
                          wallet.changeReference.pricePercentageChange24H
                        ) < 0
                          ? 'danger'
                          : 'success'
                      }
                      value={`${formatChange(
                        wallet.changeReference.pricePercentageChange24H
                      )}%`}
                      iconName="arrow"
                    />
                  </div>
                ) : isEqual(wallet.balance, wallet.balanceReference) ? (
                  <></>
                ) : (
                  formatCurrency(wallet.balance)
                )
              }
              onClick={() => handleOnClick && handleOnClick(wallet)}
            />
          ))}
      </List>
      {showEuroAccount && (
        <div css={styles.euroAccount}>
          <List>
            <ListItem
              icon={
                euroWallet ? (
                  'EUR'
                ) : (
                  <div css={styles.icon}>
                    <Icon
                      name="plus"
                      size={`calc(${spacing.xxs} + calc(${spacing.xxxs}/4))`}
                    />
                  </div>
                )
              }
              title={formatMessage({
                id: euroAccountStatus?.titleId || 'page.home.euroAccount',
              })}
              action={
                euroWallet ? (
                  euroWallet.status.includes('REQUIRED') ? (
                    <p css={styles.dot}>•</p>
                  ) : (
                    <></>
                  )
                ) : (
                  <div css={styles.chevron}>
                    <Icon name="chevron" size={spacing.xxs} />
                  </div>
                )
              }
              onClick={() => {
                if (euroWallet) setShowActivateEuro();
                else navigate('/euro-account');
              }}
              {...(euroWallet && {
                subtitle: formatMessage({
                  id: euroAccountStatus.subtitleId,
                }),
              })}
              disabled={euroWallet?.status.includes('PENDING')}
            />
          </List>
        </div>
      )}
    </div>
  );
};

const useStyles = createStyles(({ palette, spacing, shape, breakpoints }) => ({
  default: {
    position: 'relative',
    width: '100%',
  },
  chevronContainer: {
    marginTop: spacing.xxxs,
    transform: 'rotate(-90deg)',
    svg: { fill: 'none', stroke: palette.grey.x300 },
  },
  selectWallet: {
    width: '100%',
    borderRadius: shape.borderRadius,
    border: `1px solid ${palette.grey.x100}`,
    background: palette.white,
  },
  walletList: {
    marginBottom: spacing.xs,
    [breakpoints.laptopSmall]: {
      marginBottom: 0,
    },
  },
  subContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  loss: {
    svg: {
      rotate: '180deg',
    },
  },
  chevron: {
    svg: {
      fill: 'none',
      stroke: palette.black,
      rotate: '180deg',
    },
  },
  euroAccount: {
    margin: `${spacing.xs} 0`,
  },
  icon: {
    height: spacing.s,
    width: spacing.s,
    borderRadius: '50%',
    background: palette.grey.x100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: { color: palette.primary.default },
}));
