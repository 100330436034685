/* eslint-disable no-restricted-properties */
/** @jsxImportSource @emotion/react */
import { useTheme } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { Status } from 'components';
import { TopUpLayout } from './components';

export const Completed = () => {
  const { spacing } = useTheme();
  const { formatMessage } = useIntl();

  return (
    <TopUpLayout
      topChildren={
        <div>
          <Status
            heading={formatMessage({
              id: 'page.send.processing.success.title',
            })}
            subHeading={formatMessage({
              id: 'page.topUp.completed.subHeading',
            })}
            imageProps={{
              name: 'transferSuccess',
              size: spacing.xxl,
              showPlaceholder: false,
            }}
          />
        </div>
      }
    />
  );
};

export default Completed;
