/* eslint-disable no-restricted-properties */
/** @jsxImportSource @emotion/react */
import { useTheme } from '@quickbit/qb-design-system';
import { Status } from 'components';
import { TopUpLayout } from './components';

export const Failure = () => {
  const { spacing } = useTheme();

  return (
    <TopUpLayout
      topChildren={
        <div>
          <Status
            heading={'Uh oh...'}
            subHeading="Something is not right"
            imageProps={{
              name: 'unplugged',
              size: spacing.xxl,
              showPlaceholder: false,
            }}
          />
        </div>
      }
    />
  );
};

export default Failure;
