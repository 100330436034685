import {
  ACTIVE_ENVIRONMENTS,
  FEATURE_FLAG,
  FeatureFlagValues,
} from './constants';
import { getDeviceSpecificRandomValue, getFeatureFlagOverrides } from './utils';

export { FEATURE_FLAG } from './constants';

/**
 * Hook to evaluate feature flags.
 *
 * @param {string} flag - the name of the feature flag
 * @return {boolean} active
 */
export const isFeatureFlagActive = (
  flag: FeatureFlagValues,
  ignoreOverride = false
): boolean => {
  const overrides = getFeatureFlagOverrides();
  if (
    !ignoreOverride &&
    (overrides[flag] === true || overrides[flag] === false)
  ) {
    return overrides[flag];
  }

  const randomValue = getDeviceSpecificRandomValue();
  const isActiveMap: Record<FeatureFlagValues, boolean> = {
    [FEATURE_FLAG.NOT_IN_PRODUCTION]:
      process.env.REACT_APP_ENVIRONMENT !== ACTIVE_ENVIRONMENTS.production,
    [FEATURE_FLAG.ENABLE_EURO_ACCOUNT_CREATION]:
      process.env.REACT_APP_ENABLE_EURO_ACCOUNT_CREATION === 'true',
    [FEATURE_FLAG.EXPERIMENT_A]: randomValue < 0.5,
  };
  // For now the definition of all feature flags lives here and are based on environment variables
  return isActiveMap[flag];
};
