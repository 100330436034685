import { useLocalStorage, useMount } from 'react-use';
import { type QBPreferences } from 'types';
import { QB_PREFERENCES_STORAGE_KEY } from '../constants';

const defaultCountry = 'SE';

export const useLoginCountry = (): {
  loginCountry: string;
  changeLoginCountry: (country: string) => void;
} => {
  const [preferences, setPreferences] = useLocalStorage<QBPreferences>(
    QB_PREFERENCES_STORAGE_KEY,
    {
      loginCountry: defaultCountry,
    }
  );

  useMount(() => {
    if (preferences && !preferences.loginCountry) {
      setPreferences({
        ...preferences,
        loginCountry: defaultCountry,
      });
    }
  });

  const changeLoginCountry = (country: string) => {
    setPreferences((p) => ({ ...p, loginCountry: country }));
  };

  return {
    loginCountry: preferences?.loginCountry || defaultCountry,
    changeLoginCountry,
  };
};
