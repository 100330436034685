import { type UserConsents, type UserConsentReducer } from 'types';

export const userConsentReducer = (
  prev: UserConsents,
  { type, payload }: UserConsentReducer
): UserConsents => {
  switch (type) {
    case 'UPDATE':
      return { ...prev, ...payload };
    case 'SET':
      return payload;
  }
};
