/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, useState } from 'react';
import { useAsyncFn, useLocalStorage, useUpdateEffect } from 'react-use';
import { v4 as uuid } from 'uuid';
import { ErrorResponse } from 'api/fetchAPI';
import { useSetSession } from 'context';
import {
  SignInSuccessData,
  MFACodeResponse,
  completeMFA,
  initiateMFA,
  initiatePasswordLogin,
} from './api';

export const usePasswordLogin = ({
  success,
  initialEmail,
}: {
  success: (data: SignInSuccessData) => void;
  initialEmail?: string;
}): [
  {
    invalidEmail: boolean;
    login: () => Promise<SignInSuccessData>;
    loading: boolean;
    error?: ErrorResponse;
    email: string;
    setEmail: (v: string) => void;
    password: string;
    setPassword: (v: string) => void;
    rememberMe: boolean;
    setRememberMe: (v: boolean) => void;
  },
  {
    requiresMFA: boolean;
    initiateMFALoading: boolean;
    verifyLoading: boolean;
    otp: string[];
    setOtp: (v: string[]) => void;
    invalidOtp: boolean;
    sendCode: () => Promise<MFACodeResponse | undefined>;
    debugMfaCode?: MFACodeResponse;
    initiateMFAError?: ErrorResponse;
    verifyCode: () => Promise<SignInSuccessData>;
    phoneNumber?: string;
    verifyError?: ErrorResponse;
  }
] => {
  const [email, setEmail] = useState(initialEmail ?? '');
  const [password, setPassword] = useState('');
  const [deviceId] = useLocalStorage('qb_web_deviceId', uuid());
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [mfaToken, setMfaToken] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const setSession = useSetSession();

  const [{ value, loading, error }, login] = useAsyncFn(async () => {
    const authResponse = await initiatePasswordLogin(
      deviceId!,
      email,
      password,
      rememberMe
    );
    if (authResponse.mfaToken) {
      setMfaToken(authResponse.mfaToken);
    } else {
      setSession(
        {
          firstOnboardingCompleted: authResponse.firstOnboardingCompleted,
          email: authResponse.kyc.email?.address,
          fullName:
            authResponse.kyc.firstName + ' ' + authResponse.kyc.lastName,
        },
        authResponse.tokenTtlInSeconds
      );
      success(authResponse);
    }
    return authResponse;
  }, [success, deviceId, email, password, rememberMe]);

  const [{ loading: verifyLoading, error: verifyError }, verifyCode] =
    useAsyncFn(async () => {
      const completeResponse = await completeMFA(
        otp.join(''),
        mfaToken,
        deviceId!
      );
      const {
        firstOnboardingCompleted,
        kyc: { email, firstName, lastName },
        tokenTtlInSeconds,
      } = completeResponse;
      setSession(
        {
          firstOnboardingCompleted,
          email: email?.address,
          fullName: firstName + ' ' + lastName,
        },
        tokenTtlInSeconds
      );
      success(completeResponse);
      return completeResponse;
    }, [mfaToken, otp, deviceId, rememberMe, success]);

  const [
    {
      loading: initiateMFALoading,
      value: debugMfaCode,
      error: initiateMFAError,
    },
    sendCode,
  ] = useAsyncFn(() => {
    return initiateMFA(mfaToken);
  }, [mfaToken]);

  const invalidEmail = useMemo(() => !/(.+)@(.+)\.(.+)$/.test(email), [email]);

  const invalidOtp = useMemo(
    () => otp.join('').length > 0 && otp.join('').length !== 6,
    [otp]
  );

  useUpdateEffect(() => {
    if (mfaToken) sendCode();
  }, [mfaToken]);

  return [
    {
      invalidEmail,
      login,
      loading,
      error,
      email,
      setEmail,
      password,
      setPassword,
      rememberMe,
      setRememberMe,
    },
    {
      requiresMFA: mfaToken !== '',
      initiateMFALoading,
      verifyLoading,
      otp,
      setOtp,
      invalidOtp,
      sendCode,
      debugMfaCode,
      initiateMFAError,
      verifyCode,
      verifyError,
      phoneNumber:
        (value?.methods &&
          value.methods.find((e) => e.type === 'PHONE')?.phoneNumber) ||
        undefined,
    },
  ];
};
