/** @jsxImportSource @emotion/react */
import {
  Icon,
  useTheme,
  createStyles,
  Typography,
} from '@quickbit/qb-design-system';

interface Props {
  checked?: boolean;
  active?: boolean;
  number?: number;
}

export const Checkmark = ({ checked, active, number }: Props) => {
  const styles = useStyles();
  const { spacing } = useTheme();

  return (
    <div
      css={[
        styles.checkmark,
        { ...(checked ? styles.checked : {}) },
        { ...(active ? styles.active : {}) },
      ]}
    >
      {checked ? (
        <Icon name="checkmark" size={spacing.xs} />
      ) : (
        <Typography variant="bodySmallBold">{number}</Typography>
      )}
    </div>
  );
};

const useStyles = createStyles(({ palette, spacing }) => ({
  checkmark: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: spacing.xs,
    height: spacing.xs,
    borderRadius: '50%',
    background: palette.grey.x300,
    color: palette.white,
    padding: spacing.xxxs,
    paddingTop: `calc(${spacing.xxxs} + (${spacing.xxxs} / 4))`,
  },
  active: {
    border: `2px solid ${palette.primary.default}`,
  },
  checked: {
    background: palette.primary.default,
    padding: `calc(${spacing.xxxs} / 4)`,
    paddingTop: `calc((${spacing.xxxs} / 2) + (${spacing.xxxs} / 4))`,
    svg: {
      color: palette.white,
    },
  },
}));
