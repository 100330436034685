import { useTheme } from '@quickbit/qb-design-system';
import { IPiqCashierConfig } from 'paymentiq-cashier-bootstrapper';

export type Environment = 'production' | 'test' | 'development';

export const useDefaultConfig = () => {
  const { palette } = useTheme();

  const defaultConfig: IPiqCashierConfig = {
    merchantId: '',
    defaultLoader: false,
    mode: 'ecommerce',
    accountDelete: false,
    containerHeight: '100%',
    containerWidth: '100%',
    font: 'google,Inter:400,600,700',
    showAccounts: 'inline',
    showAmount: false,
    showFooter: false,
    showHeader: false,
    showFee: false,
    theme: {
      input: {
        fontSize: '17px',
        borderRadius: '4px',
      },
      labels: {
        fontSize: '17px',
      },
      cashierbackground: {
        color: palette.white,
      },
      loader: {
        color: palette.grey.x900,
      },
    },
  };

  return defaultConfig;
};
