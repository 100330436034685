/** @jsxImportSource @emotion/react */
import { createStyles, Typography } from '@quickbit/qb-design-system';
import { Image, ImageProps } from './Image';

interface Props {
  heading: string;
  subHeading: string;
  imageProps?: ImageProps;
}

export const Status = ({ heading, subHeading, imageProps }: Props) => {
  const styles = useStyle();

  return (
    <div css={styles.main}>
      <div>{imageProps && <Image {...imageProps} />}</div>
      <div css={styles.text}>
        <div css={styles.heading}>
          <Typography variant="heading2">{heading}</Typography>
        </div>
        <Typography variant="body">{subHeading}</Typography>
      </div>
    </div>
  );
};

const useStyle = createStyles(({ spacing }) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  text: { textAlign: 'center', marginTop: spacing.m },
  heading: { marginBottom: spacing.xxxs },
}));
