/** @jsxImportSource @emotion/react */
import {
  Card,
  Icon,
  Logo,
  createStyles,
  useTheme,
} from '@quickbit/qb-design-system';
import { ReactNode, useRef } from 'react';

export interface Props {
  children?: ReactNode;
  progress?: number;
  topChildren?: ReactNode;
  showLogo?: boolean;
  onCancel?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const TopUpLayout = ({
  children,
  topChildren,
  progress,
  showLogo = true,
  onCancel = undefined,
}: Props) => {
  const styles = useStyles();
  const { spacing } = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div data-testid="layout-wrapper" css={[styles.wrapper]} ref={ref}>
      <main css={[styles.container]}>
        {progress !== undefined && (
          <div css={styles.progressWidth}>
            <div css={styles.progressContainer}>
              <div
                css={[
                  styles.progressBar,
                  { width: `${progress * 100}%` },
                  progress === 1 && { borderRadius: 0 },
                ]}
              />
            </div>
          </div>
        )}
        {showLogo && <Logo width={spacing.xxl} />}
        {onCancel && (
          <div css={styles.cancelButton} onClick={onCancel}>
            <Icon name="close" size={spacing.xs} />
          </div>
        )}
        {topChildren}
      </main>

      {children && <Card css={styles.footer}>{children}</Card>}
    </div>
  );
};

const useStyles = createStyles(({ spacing, breakpoints, palette, shape }) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.tabletSmall]: {
      maxWidth: '100%',
      display: 'block',
      height: 'unset',
    },
  },
  cancelButton: {
    position: 'absolute',
    right: 12,
    top: 12,
    cursor: 'pointer',
    [breakpoints.tabletSmall]: {
      right: 0,
      top: 18,
    },
  },
  container: {
    flex: 1,
    paddingTop: spacing.m,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing.xxs,
    margin: `0 ${spacing.xxs}`,
    [breakpoints.tabletSmall]: {
      margin: 0,
    },
  },
  marginWrapper: {
    margin: `0 ${spacing.xxs}`,
    [breakpoints.tabletSmall]: {
      margin: 0,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [breakpoints.tabletSmall]: {
      flex: 1,
      margin: 'unset',
      marginTop: spacing.m,
    },
  },
  progressBar: {
    background: palette.primary.default,
    height: spacing.xxxs,
    borderRadius: `0 ${shape.borderRadius} ${shape.borderRadius} 0`,
  },
  progressContainer: {
    background: palette.primary.x50,
    height: spacing.xxxs,
    width: '100%',
  },
  progressWidth: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
