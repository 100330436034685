/** @jsxImportSource @emotion/react */
import { Button, Typography, useTheme } from '@quickbit/qb-design-system';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn, useMount, useSessionStorage } from 'react-use';
import { ErrorResponse } from 'api/fetchAPI';
import { QBLoader, ErrorComponent } from 'components';
import { useMixpanel } from 'context';
import { useTokenPolling } from 'hooks';
import { useSetTopUpData, useTopUpData } from 'top-up-pages/context';
import {
  BuyOrderStatus,
  RedirectData,
  buyOrderStatus,
  createBuyOrder,
} from '../api';
import { Redirect } from './Redirect';

const TOP_UP_BUY_PREVIEW_ID_SESSION_DATA_KEY = 'qb_web_topUpBuyPreviewId';

interface BuySessionData {
  buyPreviewId: string;
  receiveOrderPreviewId: string;
  redirect?: RedirectData;
}

export const FrontBuy = () => {
  const { formatMessage } = useIntl();
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const topUpData = useTopUpData();
  const setTopUpData = useSetTopUpData();
  const { hasCardData, receiveOrderPreviewId, buyPreviewId, merchantName } =
    topUpData;
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Buy',
  });

  const [buySessionData, setBuySessionData] =
    useSessionStorage<BuySessionData | null>(
      TOP_UP_BUY_PREVIEW_ID_SESSION_DATA_KEY,
      null
    );

  if (!hasCardData) {
    throw Error('Missing card data');
  }
  if (!buyPreviewId) {
    throw Error('Invalid state');
  }

  const poll = useCallback(async (previewId: string) => {
    const data = await buyOrderStatus(previewId);
    if (data.status === 'FAILED') {
      throw {
        errorCode: 'PAYMENT_FAILED',
        reason: 'Payment failed.',
      } as ErrorResponse;
    }
    return (data.status === 'COMPLETE' ? data : {}) as BuyOrderStatus;
  }, []);

  const success = useCallback(() => {
    mixpanelTrackEvent('Buy completed', {
      description: 'User completed the buy process.',
      merchantName,
      frontBuy: true,
      buyPreviewId: buySessionData ? buySessionData.buyPreviewId : buyPreviewId,
    });
    setTopUpData({ shouldSendAutomatically: true });
    setBuySessionData(null);
    navigate(`/top-up`);
  }, [
    navigate,
    mixpanelTrackEvent,
    setTopUpData,
    setBuySessionData,
    merchantName,
    buyPreviewId,
    buySessionData,
  ]);

  const initiate = useCallback(async () => {
    return {
      token: buySessionData ? buySessionData.buyPreviewId : buyPreviewId,
    };
  }, [buySessionData, buyPreviewId]);

  const { start, error, polling } = useTokenPolling({
    initiate,
    poll,
    success,
  });

  const [{ loading, error: buyError, value }, buy] = useAsyncFn(async () => {
    const buyResponse = await createBuyOrder(buyPreviewId);
    setBuySessionData({
      buyPreviewId: buyPreviewId,
      redirect: buyResponse.redirect,
      receiveOrderPreviewId,
    });
    if (!buyResponse.redirect) {
      mixpanelTrackEvent('Buy completed', {
        description: 'User completed the buy process with card details given.',
        merchantName,
      });
      start();
      return buyResponse;
    }
    start();
    return buyResponse;
  }, [
    buyPreviewId,
    mixpanelTrackEvent,
    start,
    receiveOrderPreviewId,
    setBuySessionData,
  ]);

  useMount(() => {
    if (
      buySessionData?.buyPreviewId &&
      buySessionData?.receiveOrderPreviewId === receiveOrderPreviewId
    ) {
      start();
    } else if (
      buySessionData &&
      buySessionData.receiveOrderPreviewId !== receiveOrderPreviewId
    ) {
      setBuySessionData(null);
    }
  });

  if (error || buyError) {
    return (
      <div
        css={{
          position: 'fixed',
          inset: 0,
          background: 'white',
          zIndex: 999,
        }}
      >
        <ErrorComponent
          error={error ?? buyError}
          tryAgainAction={() => {
            setTopUpData({
              ...topUpData,
              hasCardData: false,
            });
            navigate(0);
          }}
        />
      </div>
    );
  }

  return (
    <>
      {!value && (
        <Button
          onClick={buy}
          text={formatMessage(
            {
              id: 'page.topUp.buy.button',
            },
            { merchantName }
          )}
          loading={loading || polling}
          disabled={loading || polling}
        />
      )}
      {value && (
        <div
          css={{
            position: 'fixed',
            inset: 0,
            background: 'white',
            zIndex: 999,
          }}
        >
          <div css={{ marginTop: spacing.s }}>
            {(loading || polling) && (
              <>
                <QBLoader />
                <div
                  css={{
                    position: 'fixed',
                    inset: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div css={{ marginTop: spacing.l }}>
                    <Typography>
                      <FormattedMessage id="page.topUp.buy.processing" />
                    </Typography>
                  </div>
                </div>
              </>
            )}
            {buySessionData?.redirect && (
              <Redirect {...buySessionData.redirect} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
