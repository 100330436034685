import { type BrowserOptions, BrowserTracing, Replay } from '@sentry/react';

export const SENTRY_OPTIONS: BrowserOptions = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['*'],
    }),
    new Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE), //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE
  ), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: Number(
    process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE
  ), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: process.env.REACT_APP_SENTRY_RELEASE, // Sets the release version of your application
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT, // Sets the environment of your application
};
