import { useCallback, useState } from 'react';
import { useInterval } from 'react-use';
import { ErrorResponse } from 'api/fetchAPI';

const POLLING_INTERVAL = 5000;

export const useTokenPolling = <I extends object, R extends object>({
  initiate,
  poll,
  success,
}: {
  initiate: (input?: I) => Promise<{
    token: string;
  }>;
  poll: (pollToken: string) => Promise<R>;
  success: (data: R) => void;
}) => {
  const [token, setToken] = useState<string | null>(null);
  const [interval, setInterval] = useState<number | null>(null);
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isAwaitingPoll, setIsAwaitingPoll] = useState(false);

  const start = useCallback(
    async (input?: I) => {
      setError(null);
      setInterval(POLLING_INTERVAL);
      try {
        const init = await initiate(input);
        setToken(init.token);
      } catch (e) {
        setError(e as ErrorResponse);
        setInterval(null);
      }
    },
    [initiate]
  );

  const cancel = useCallback(() => {
    setError(null);
    setToken(null);
    setInterval(null);
  }, []);

  const poller = useCallback(async () => {
    if (!token) return null;
    if (isAwaitingPoll) return null;
    setIsAwaitingPoll(true);
    try {
      const pollingStatus = await poll(token);
      if (Object.keys(pollingStatus).length > 0) {
        setInterval(null);
        success(pollingStatus);
      }
    } catch (e) {
      setInterval(null);
      setError(e as ErrorResponse);
    }
    setIsAwaitingPoll(false);
  }, [token, success, poll, isAwaitingPoll]);

  useInterval(poller, interval);

  return { start, cancel, polling: interval !== null, error };
};
