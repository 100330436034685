import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMixpanel, useSetKycData } from 'context';
import { SignInSuccessData } from 'hooks/login/api';
import { useTopUpData } from 'top-up-pages/context';

export const useHandleSuccessSignIn = () => {
  const navigate = useNavigate();
  const setkyc = useSetKycData();
  const { email } = useTopUpData();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Login',
  });

  const handleRedirect = useCallback(
    (data: SignInSuccessData) => {
      const {
        kyc: { actions },
        requestConsent,
      } = data;
      const { kyc } = data;

      mixpanelTrackEvent('Login completed', {
        description: 'User completed the login process.',
        accountStatus: kyc.accountStatus,
      });

      switch (kyc.accountStatus) {
        case 'PENDING_CLOSURE':
          return navigate('/top-up/account-on-hold');
        case 'BLOCKED':
          return navigate('/top-up/account-blocked');
        case 'CLOSED':
          return navigate('/top-up/account-closed');
      }

      if (!('email' in kyc) && email) {
        kyc.email = { address: email, verified: false };
      }

      setkyc({ ...kyc });

      if (
        actions.some(
          (action) => action.status !== 'APPROVED' && action.isRequired
        )
      ) {
        if (requestConsent) return navigate('/top-up/onboarding/consent');
        return navigate('/top-up/onboarding/steps');
      }

      return navigate('/top-up');
    },
    [navigate, setkyc, email, mixpanelTrackEvent]
  );

  return handleRedirect;
};
