/** @jsxImportSource @emotion/react */
import {
  Button,
  ListItem,
  Typography,
  useTheme,
} from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAsync, useMount } from 'react-use';
import { ErrorComponent, QBLoader } from 'components';
import { RedirectWithReturnLink, useMixpanel } from 'context';
import { useFormatCurrency } from 'hooks';
import { TopUpLayout } from 'top-up-pages/components';
import { useSetTopUpData, useTopUpData } from '../context';
import { createBuyPreview } from './api';
import { FrontBuy } from './FrontBuy/FrontBuy';

export const Buy = () => {
  const { formatMessage } = useIntl();
  const { palette, spacing } = useTheme();
  const navigate = useNavigate();
  const formatCurrency = useFormatCurrency();
  const { receiveOrderPreviewId, hasCardData, merchantName, fiatAmount } =
    useTopUpData();
  const setTopUpData = useSetTopUpData();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Buy',
  });

  if (!receiveOrderPreviewId) {
    throw Error('Invalid state');
  }

  const { value, loading, error } = useAsync(async () => {
    const previewResponse = await createBuyPreview(receiveOrderPreviewId);
    setTopUpData({ buyPreviewId: previewResponse.id });
    return previewResponse;
  }, [receiveOrderPreviewId]);

  useMount(() => {
    mixpanelTrackEvent('Buy started', {
      description: 'User started the buy process.',
      merchantName,
    });
  });

  if (error) {
    if (error.errorCode === 'COMPLETED_BUY_ORDER') {
      return <RedirectWithReturnLink to={`/top-up`} />;
    }
    if (error.errorCode === 'COMPLETED_PREVIEW') {
      return <RedirectWithReturnLink to={`/top-up/completed`} />;
    }
    if (error.errorCode === 'DELETED_PREVIEW') {
      return <RedirectWithReturnLink to={`/top-up/cancelled`} />;
    }
    return <ErrorComponent error={error} tryAgainAction={() => navigate(-1)} />;
  }

  if (loading || !value) return <QBLoader />;

  let amountText = undefined;
  if (fiatAmount) {
    const referenceAmount = value.sourceAmount
      ? value.sourceAmount
      : value.totalReference;
    if (referenceAmount.currencyCode === fiatAmount.currencyCode) {
      amountText = formatCurrency(fiatAmount);
    } else {
      amountText = `${formatCurrency(fiatAmount)} / ${formatCurrency(
        referenceAmount
      )}`;
    }
  }
  return (
    <TopUpLayout
      topChildren={
        <Typography variant="body">
          {formatMessage(
            {
              id: 'page.topUp.buy.proceedToComplete',
            },
            { merchantName }
          )}
        </Typography>
      }
    >
      {amountText && (
        <ListItem
          title={formatMessage({ id: 'page.topUp.buy.total' })}
          content={amountText}
        />
      )}
      <div css={{ marginBottom: spacing.xxxs, fontStyle: 'italic' }}>
        <Typography variant="bodySmall" color={palette.grey.default}>
          {formatMessage(
            { id: 'page.topUp.buy.infoBanner' },
            { merchantName, currency: fiatAmount?.currencyCode }
          )}
        </Typography>
      </div>
      {hasCardData && <FrontBuy />}
      {!hasCardData && (
        <Button
          variant="primary"
          onClick={() => navigate('/top-up/buy/cashier')}
          text={formatMessage(
            {
              id: 'page.topUp.buy.button',
            },
            { merchantName }
          )}
        />
      )}
    </TopUpLayout>
  );
};

export default Buy;
