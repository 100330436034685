import { useIntl } from 'react-intl';

export const useFormatChange = () => {
  const { formatNumber } = useIntl();

  return (value: string) =>
    formatNumber(
      parseFloat(
        (parseFloat(value[0] === '-' ? value.slice(1) : value) * 100).toFixed(2)
      ),
      {
        minimumSignificantDigits: 2,
      }
    );
};
