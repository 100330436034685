/** @jsxImportSource @emotion/react */
import {
  createStyles,
  Icon,
  IconName,
  Typography,
  useTheme,
} from '@quickbit/qb-design-system';
import { ReactNode, useState } from 'react';
import { PullUpModal } from 'components';

interface Props {
  title?: string;
  onBack?: () => void;
  actions?: {
    icon: IconName;
    title: string;
    content: ReactNode;
  }[];
  breadcrumb?: string;
  progress?: number;
  isInModal?: boolean;
}

export const Header = ({
  title,
  onBack,
  breadcrumb,
  progress,
  actions,
  isInModal,
}: Props) => {
  const styles = useStyles();
  const { palette, spacing } = useTheme();

  const [showActionModal, setShowActionModal] = useState<IconName>();

  if (progress && (progress < 0 || progress > 1)) {
    throw Error('Progress has to be 0 <= x <= 1');
  }

  return (
    <>
      <header css={[styles.header, !isInModal && styles.largeHeader]}>
        <div css={[styles.content, !isInModal && styles.largeContent]}>
          {onBack && (
            <div
              css={[styles.back, !isInModal && styles.largeBack]}
              onClick={() => {
                onBack && onBack();
              }}
            >
              <Icon name={'chevron'} />
            </div>
          )}
          {title && (
            <div css={[styles.title, !isInModal && styles.largeTitle]}>
              {breadcrumb && (
                <div
                  css={[
                    styles.breadcrumb,
                    !isInModal && styles.largeBreadcrumb,
                  ]}
                >
                  <div css={{ cursor: 'pointer' }} onClick={onBack}>
                    <Typography color={palette.grey.x300} variant="subtitle">
                      {breadcrumb}
                    </Typography>
                  </div>
                  <Icon name="chevron" size={spacing.xxs} />
                </div>
              )}
              <Typography variant="subtitle">{title}</Typography>
            </div>
          )}
          {actions &&
            actions.map(({ icon }) => (
              <div key={icon} css={styles.icon}>
                <div
                  onClick={() => {
                    setShowActionModal(icon);
                  }}
                >
                  <Icon name={icon} />
                </div>
              </div>
            ))}
        </div>
      </header>
      {progress !== undefined && (
        <div css={styles.progressWidth}>
          <div css={styles.progressContainer}>
            <div
              css={[
                styles.progressBar,
                { width: `${progress * 100}%` },
                progress === 1 && { borderRadius: 0 },
              ]}
            />
          </div>
        </div>
      )}
      {actions &&
        actions.map((action) =>
          showActionModal === action.icon ? (
            <PullUpModal
              key={action.icon}
              title={action.title}
              onClose={() => setShowActionModal(undefined)}
            >
              {action.content}
            </PullUpModal>
          ) : null
        )}
    </>
  );
};

const useStyles = createStyles(({ spacing, palette, shape, breakpoints }) => ({
  header: {
    position: 'sticky',
    zIndex: 99,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    padding: spacing.xxs,
    borderBottom: `1px solid ${palette.grey.x100}`,
    backgroundColor: palette.white,
    width: '100%',
    justifyContent: 'center',
  },
  largeHeader: {
    [breakpoints.laptopSmall]: {
      border: `1px solid ${palette.grey.x100}`,
      borderRadius: shape.borderRadius,
      display: 'block',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxxs,
    width: '100%',
    justifyContent: 'center',
    margin: '0 auto',
    svg: {
      stroke: palette.grey.x300,
      fill: 'none',
      rotate: '180deg',
    },
  },
  largeTitle: {
    [breakpoints.laptopSmall]: {
      margin: '0',
      justifyContent: 'start',
    },
  },
  breadcrumb: {
    display: 'none',
  },
  largeBreadcrumb: {
    [breakpoints.laptopSmall]: {
      display: 'flex',
      alignItems: 'center',
      gap: spacing.xxxs,
    },
  },
  icon: {
    right: 0,
    position: 'absolute',
    cursor: 'pointer',
  },
  back: {
    position: 'absolute',
    left: 0,
    cursor: 'pointer',
    svg: { fill: 'none', stroke: palette.black },
  },
  largeBack: {
    [breakpoints.laptopSmall]: {
      display: 'none',
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  largeContent: {
    [breakpoints.laptopSmall]: {
      padding: `calc(${spacing.xxxs}/2) 0`,
    },
  },
  progressBar: {
    background: palette.primary.default,
    height: spacing.xxxs,
    borderRadius: `0 ${shape.borderRadius} ${shape.borderRadius} 0`,
  },
  progressContainer: {
    background: palette.primary.x50,
    height: spacing.xxxs,
    width: '100%',
  },
  progressWidth: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));
