/** @jsxImportSource @emotion/react */
import {
  ListItem,
  Button,
  Typography,
  useTheme,
} from '@quickbit/qb-design-system';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { ErrorComponent, QBLoader, MicroArticlePullUpModal } from 'components';
import { RedirectWithReturnLink, useMixpanel } from 'context';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { TopUpLayout } from 'top-up-pages/components';
import { useTopUpData, useSetTopUpData } from '../context';
import { createSendPreview, cancelOrder } from './api';

export const Preview = () => {
  const { palette, spacing } = useTheme();
  const { formatMessage } = useIntl();
  const formatCurrency = useFormatCurrency();
  const navigate = useNavigate();
  const setTopUpData = useSetTopUpData();

  const {
    receiveOrderPreviewId,
    merchantName,
    fiatAmount,
    shouldSendAutomatically,
  } = useTopUpData();
  const [showFeeModal, setShowFeeModal] = useState(false);

  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Preview',
  });

  const { value, loading, error } = useAsync(async () => {
    const preview = await createSendPreview(receiveOrderPreviewId);
    setTopUpData({
      cryptoAmount: preview.amount,
      previewId: preview.id,
    });
    return preview;
  }, [receiveOrderPreviewId]);

  useEffect(() => {
    if (value) {
      mixpanelTrackEvent('Preview started', {
        description: 'User started order preview (review) step.',
        merchantName,
      });

      if (shouldSendAutomatically) {
        mixpanelTrackEvent('Preview completed', {
          description: 'User completed the order preview step.',
          merchantName,
        });
        navigate(`/top-up/processing`);
      }
    }
  }, [
    value,
    mixpanelTrackEvent,
    navigate,
    merchantName,
    shouldSendAutomatically,
  ]);

  useEffect(() => {
    if (error) {
      mixpanelTrackEvent('Logged in and Preview started', {
        description: `User started order preview step with a state: ${error.errorCode}`,
        state: error.errorCode,
        merchantName,
      });
    }
  }, [mixpanelTrackEvent, merchantName, error]);

  if (error) {
    if (error.errorCode === 'COMPLETED_PREVIEW') {
      return <RedirectWithReturnLink to={`/top-up/completed`} />;
    }
    if (error.errorCode === 'INSUFFICIENT_FUNDS') {
      setTopUpData({ shouldSendAutomatically: undefined });
      return <RedirectWithReturnLink to={`/top-up/buy`} />;
    }
    if (error.errorCode === 'DELETED_PREVIEW') {
      return <RedirectWithReturnLink to={`/top-up/cancelled`} />;
    }
    return <ErrorComponent error={error} tryAgainAction={() => navigate(-1)} />;
  }

  if (loading || !value) return <QBLoader />;

  const { amountReference, estimatedTotal } = value;

  let amountText = undefined;
  if (fiatAmount) {
    if (amountReference.currencyCode === fiatAmount.currencyCode) {
      amountText = formatCurrency(fiatAmount);
    } else {
      amountText = `${formatCurrency(fiatAmount)} / ${formatCurrency(
        amountReference
      )}`;
    }
  }

  const onCancelOrder = async () => {
    await cancelOrder(receiveOrderPreviewId);
    mixpanelTrackEvent('Order cancelled', {
      description: 'User cancelled the order preview step.',
      merchantName,
    });
    navigate('/top-up/cancelled');
  };

  return (
    <TopUpLayout
      onCancel={onCancelOrder}
      topChildren={
        <Typography variant="body">
          {formatMessage({ id: 'page.topUp.send.title' })}
        </Typography>
      }
    >
      {amountText && (
        <ListItem
          title={formatMessage({ id: 'page.topUp.send.total' })}
          content={amountText}
          subtitle={formatMessage({ id: 'page.topUp.send.bitcoin' })}
          subcontent={formatCurrency(estimatedTotal)}
        />
      )}
      {showFeeModal && (
        <MicroArticlePullUpModal
          articleKey="article.serviceFee"
          onClose={() => setShowFeeModal(false)}
        />
      )}
      <div css={{ marginBottom: spacing.xxxs, fontStyle: 'italic' }}>
        <Typography variant="bodySmall" color={palette.grey.default}>
          {formatMessage(
            {
              id: 'page.topUp.send.infoBanner',
            },
            { merchantName }
          )}
        </Typography>
      </div>
      <Button
        text={formatMessage(
          {
            id: 'page.topUp.send.send',
          },
          { merchantName }
        )}
        onClick={() => {
          mixpanelTrackEvent('Preview completed', {
            description: 'User completed the order preview step.',
            merchantName,
          });
          navigate(`/top-up/processing`);
        }}
      />
    </TopUpLayout>
  );
};

export default Preview;
