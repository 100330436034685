/** @jsxImportSource @emotion/react */
import {
  Typography,
  UtilityButton,
  useTheme,
} from '@quickbit/qb-design-system';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { Status } from 'components';
import { useMixpanel } from 'context';
import { useFormatCurrency } from 'hooks';
import { TopUpLayout } from 'top-up-pages/components';
import { useTopUpData } from 'top-up-pages/context';
import { inIFrame } from 'utils';

export const Success = () => {
  const { formatMessage } = useIntl();
  const { spacing } = useTheme();
  const formatCurrency = useFormatCurrency();
  const { cryptoAmount, redirectUrl, merchantName, fiatAmount } =
    useTopUpData();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Success',
  });

  useMount(() => {
    mixpanelTrackEvent('Send completed', {
      description: 'User successfully pays the order.',
      merchantName,
    });
  });

  useEffect(() => {
    if (redirectUrl)
      setTimeout(() => {
        const windowLocation = inIFrame()
          ? window.parent.location
          : window.location;
        windowLocation.replace(redirectUrl);
      }, 1000);
  }, [redirectUrl]);

  return (
    <TopUpLayout
      topChildren={
        <div>
          <Status
            heading={formatMessage({
              id: 'page.send.processing.success.title',
            })}
            subHeading={
              fiatAmount && cryptoAmount
                ? formatMessage(
                    {
                      id: 'page.topUp.success.body',
                    },
                    {
                      amount: formatCurrency(fiatAmount),
                      cryptoAmount: formatCurrency(cryptoAmount),
                      merchantName,
                    }
                  )
                : ''
            }
            imageProps={{
              name: 'transferSuccess',
              size: spacing.xxl,
              showPlaceholder: false,
            }}
          />
          {redirectUrl && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: spacing.xs,
                gap: spacing.xxxs,
              }}
            >
              <Typography>
                {formatMessage({
                  id: 'page.topUp.success.redirect',
                })}
              </Typography>
              <a href={redirectUrl}>
                <UtilityButton
                  text={formatMessage(
                    {
                      id: 'page.topUp.success.button',
                    },
                    { merchantName: merchantName ?? '' }
                  )}
                />
              </a>
            </div>
          )}
        </div>
      }
    />
  );
};

export default Success;
