import { useRef } from 'react';

/**
 * Mounting of components happend in the order child -> parent -> parent.
 * Sometimes we want to execute code in the other direction. The will mount
 * hook allows you to do that.
 * Example: 
 * Given: 
 * <A>
    <B />
    <C />
  </A>
  A useWillMount
  B useWillMount
  C useWillMount
  B useMount
  C useMount
  A useMount
 */
export const useWillMount = (callback: () => void) => {
  const willMount = useRef(true);

  if (willMount.current) callback();

  willMount.current = false;
};
