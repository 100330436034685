/** @jsxImportSource @emotion/react */
import { Button } from '@quickbit/qb-design-system';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Country, EidProviderName } from 'types';

interface Props {
  selectedCountry?: Country;
  onLogin: (provider: EidProviderName) => void;
  fetchingAuthData: boolean;
  allowEmailPasswordLogin?: boolean;
}

export const AuthMethodsLogin = ({
  selectedCountry,
  onLogin,
  fetchingAuthData,
  allowEmailPasswordLogin = true,
}: Props) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  return (
    <>
      {selectedCountry &&
        selectedCountry.auth.map(({ provider, method }) => (
          <Fragment key={provider ?? method}>
            {method !== 'EMAIL' && provider && (
              <Button
                onClick={() => onLogin(provider)}
                text={formatMessage({
                  id: 'continue',
                })}
                loading={fetchingAuthData}
                disabled={fetchingAuthData}
              />
            )}
            {method === 'EMAIL' && allowEmailPasswordLogin && (
              <Button
                text={formatMessage({
                  id: 'page.login.button.onfido.login',
                })}
                onClick={() => navigate('email')}
                variant="secondary"
              />
            )}
          </Fragment>
        ))}
    </>
  );
};
