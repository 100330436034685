import { type Currency } from '@quickbit/qb-design-system';
import { useParams } from 'react-router-dom';
import { useSelectedWallet as getSelectedWallet } from 'context';

export const useSelectedWallet = () => {
  const { currencyCode } = useParams();
  const selectedWallet = getSelectedWallet(currencyCode as Currency);

  return selectedWallet;
};
