import { type ErrorResponse } from 'api/fetchAPI';

export const getErrorMessage = (error: ErrorResponse) => {
  if (error.errorCode === 'FORM_VALIDATION_FAILED')
    return [
      ...error.formErrors.map((err) => err.reason),
      `Support ID: ${error.tracingId}`,
    ];
  return [error.reason, `Support ID: ${error.tracingId}`];
};
