/* eslint-disable no-restricted-properties */
/** @jsxImportSource @emotion/react */
import { useTheme } from '@quickbit/qb-design-system';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { Status } from 'components';
import { useMixpanel } from 'context';
import { inIFrame } from 'utils';
import { TopUpLayout } from './components';
import { useTopUpData } from './context';

export const Cancelled = () => {
  const { spacing } = useTheme();
  const { formatMessage } = useIntl();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Cancelled',
  });
  const { failureRedirectUrl } = useTopUpData();

  useMount(() => {
    mixpanelTrackEvent('Order cancelled', {
      description: 'User ended up on the cancelled page.',
    });
  });

  useEffect(() => {
    if (failureRedirectUrl)
      setTimeout(() => {
        const windowLocation = inIFrame()
          ? window.parent.location
          : window.location;
        windowLocation.replace(failureRedirectUrl);
      }, 1000);
  }, [failureRedirectUrl]);

  return (
    <TopUpLayout
      topChildren={
        <div>
          <Status
            heading={formatMessage({
              id: 'page.topup.error.transactionStopped.title',
            })}
            subHeading={
              failureRedirectUrl
                ? formatMessage({ id: 'page.topUp.failure.redirect' })
                : formatMessage({
                    id: 'page.topup.error.transactionStopped.info',
                  })
            }
            imageProps={{
              name: 'unplugged',
              size: spacing.xxl,
              showPlaceholder: false,
            }}
          />
        </div>
      }
    />
  );
};

export default Cancelled;
