import { fetchAPI } from 'api';
import { type Environment } from './hooks/useDefaultConfig';

interface PIQCashierResponse {
  merchantId: string;
  sessionId: string;
  previewId: string;
  userId: string;
  amount: string;
  environment: Environment;
  showReceipt: boolean;
  storeAccount: boolean;
  saveAccountOption: boolean;
}

export const fetchPIQCashierData = async (previewId: string) => {
  return await fetchAPI<PIQCashierResponse>(`piq/cashier/data/${previewId}`);
};

export const handlePiqFailure = async (body: {
  data: Record<string, string>;
  previewId: string;
}) => {
  return await fetchAPI(`payment/failure`, {
    method: 'POST',
    body,
  });
};
