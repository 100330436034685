/** @jsxImportSource @emotion/react */
import {
  useTheme,
  Button,
  loaderImage,
  ListItem,
  Typography,
  Snackbar,
  Logo,
} from '@quickbit/qb-design-system';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import {
  AuthMethodsLogin,
  CountrySelect,
  Markdown,
  QBLoader,
  RememberMe,
} from 'components';
import { useMixpanel } from 'context';
import { useFormatCurrency } from 'hooks';
import { useEidLogin } from 'hooks/login';
import { EidProviderName } from 'types';
import { isOnfidoCountry, getErrorMessage } from 'utils';
import { TopUpLayout } from './components';
import { useTopUpData } from './context';
import { useHandleSuccessSignIn } from './hooks/useHandleSuccessSignIn';

export const Login = () => {
  const { spacing, palette } = useTheme();
  const formatCurrency = useFormatCurrency();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const handleSuccessSignIn = useHandleSuccessSignIn();
  const { countryCode, existingUser, merchantName, fiatAmount } =
    useTopUpData();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Login',
  });

  const {
    start,
    cancel,
    pollingError,
    loadingCountries,
    isPollingLogin,
    fetchingAuthData,
    appCountries,
    rememberMe,
    setRememberMe,
    changeLoginCountry,
    selectedCountry,
  } = useEidLogin({
    success: handleSuccessSignIn,
  });

  useUpdateEffect(() => {
    if (appCountries) {
      if (
        countryCode &&
        appCountries.find((appCountry) => appCountry.code === countryCode)
      ) {
        changeLoginCountry(countryCode);
        if (isOnfidoCountry(countryCode) && existingUser) {
          navigate('email');
        }
      }
    }
  }, [appCountries]);

  useEffect(() => {
    mixpanelTrackEvent('Login landing page', {
      description: 'User entered login landing page.',
    });
  }, [mixpanelTrackEvent]);

  const onLogin = (provider: EidProviderName) => {
    start({ provider });
    mixpanelTrackEvent('Login started', {
      description: 'User started the login process.',
    });
  };

  if (loadingCountries) return <QBLoader />;

  return (
    <TopUpLayout
      showLogo={false}
      topChildren={
        <>
          {merchantName && (
            <>
              <div css={{ textAlign: 'center' }}>
                <Typography variant="heading1">
                  {formatMessage(
                    {
                      id: 'page.topUp.login.payToMerchant',
                    },
                    { merchantName }
                  )}
                </Typography>
              </div>
              <div>
                <Typography variant="bodySmallest">Powered by</Typography>
                <Logo />
              </div>
            </>
          )}
          <Typography variant="body">
            {formatMessage({
              id: !isPollingLogin
                ? 'page.topUp.login.verifyInfo'
                : 'page.login.authenticating',
            })}
          </Typography>

          {!isPollingLogin && (
            <div css={{ marginBottom: spacing.xxs }}>
              <CountrySelect
                appCountries={appCountries}
                country={selectedCountry}
                setCountry={(c) => changeLoginCountry(c.code)}
              />
            </div>
          )}
        </>
      }
    >
      {fiatAmount && (
        <ListItem
          title={formatMessage({ id: 'page.topUp.buy.total' })}
          content={formatCurrency(fiatAmount)}
        />
      )}
      {!isPollingLogin && (
        <>
          <RememberMe rememberMe={rememberMe} onChange={setRememberMe} />
          <div css={{ fontStyle: 'italic' }}>
            <Typography variant="bodySmall" color={palette.grey.default}>
              {formatMessage(
                {
                  id: 'page.topUp.login.infoBanner',
                },
                { merchantName, currency: fiatAmount?.currencyCode }
              )}
            </Typography>
          </div>
          <div css={{ fontStyle: 'italic' }}>
            <Typography variant="bodySmallest" color={palette.grey.default}>
              <Markdown
                markdown={formatMessage({
                  id: 'page.topUp.login.termsAndConditions',
                })}
              />
            </Typography>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: spacing.xxxs,
              width: '100%',
            }}
          >
            <AuthMethodsLogin
              selectedCountry={selectedCountry}
              fetchingAuthData={fetchingAuthData}
              onLogin={onLogin}
            />
          </div>
        </>
      )}
      {isPollingLogin && (
        <>
          <img src={loaderImage} css={{ width: spacing.l }} />
          <Button
            variant="secondary"
            text={formatMessage({
              id: 'cancel',
            })}
            onClick={cancel}
          />
        </>
      )}
      {!isPollingLogin && !pollingError && <Outlet />}
      {pollingError && (
        <Snackbar
          open={!!pollingError}
          message={getErrorMessage(pollingError)}
          variant="danger"
        />
      )}
    </TopUpLayout>
  );
};
