/** @jsxImportSource @emotion/react */
import { useTheme } from '@quickbit/qb-design-system';
import { Outlet } from 'react-router-dom';
import { OnboardingProvider, type OnboardingUrls } from 'context/Onboarding';

const topUpOnboardingUrls: OnboardingUrls = {
  STEPS: '/top-up/onboarding/steps',
  ADDRESS: '/top-up/onboarding/contact-information',
  EMAIL: '/top-up/onboarding/email-verification',
  PASSWORD: '/top-up/onboarding/set-password',
  PHONE: '/top-up/onboarding/phone-verification',
  ONBOARDING: '/top-up/onboarding/kyc',
  SOURCE_OF_FUNDS: '/top-up/onboarding/source-of-funds',
  SUCCESS: '/top-up',
};

export const OnboardingLayout = () => {
  const { breakpoints, spacing } = useTheme();
  return (
    <OnboardingProvider urls={topUpOnboardingUrls} forceKyc={false}>
      <div
        css={{
          [breakpoints.tabletSmall]: {
            header: {
              background: 'transparent',
              padding: `${spacing.xxxs} 0`,
              div: {
                display: 'flex',
                justifyContent: 'center',
              },
            },
          },
        }}
      >
        <Outlet />
      </div>
    </OnboardingProvider>
  );
};

export default OnboardingLayout;
