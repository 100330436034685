import { Currency, removeScaleFromMoney } from '@quickbit/qb-design-system';
import { Money, MoneyScaled } from 'types';
import { fetchAPI } from './fetchAPI';

export type TransactionType = 'BUY' | 'SEND' | 'SWAP' | 'SELL';

/**
 * On buy and send
 * { amountReference: 1 SEK, rates: [{ from SEK to BTC}] }
 * On Sell
 * { amountFromCurrency: 1 BTC, rates: [{ from BTC to EUR}] }
 * On Swap
 * { amountFromCurrency: 1 BTC, rateReference: 92352 SEK, rates: [{ from BTC to EUR (and other Crypto)}] }
 */
export const fetchExchangeRates = async (
  type: TransactionType,
  baseCurrency: Currency
): Promise<Money[]> => {
  const { rates, rateReference } = await fetchAPI<{
    rates: MoneyScaled[];
    rateReference?: MoneyScaled;
    amountReference?: MoneyScaled;
  }>(
    `${type.toLowerCase()}/exchange-rates${
      ['SELL', 'SWAP'].includes(type) ? '/' + baseCurrency : ''
    }`
  );

  const moneyRates = rates.map((ms) => ({
    amount: removeScaleFromMoney(ms),
    currencyCode: ms.currencyCode,
  }));

  if (rateReference) {
    moneyRates.push({
      amount: removeScaleFromMoney(rateReference),
      currencyCode: rateReference.currencyCode,
    });
  }

  return moneyRates;
};
