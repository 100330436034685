import Cookies from 'js-cookie';
import { camelCase, snakeCase, isArray, transform, isObject } from 'lodash';
import { getLocaleFromStorage } from 'hooks';
import { DEEPLINK_STORAGE_KEY, KYC_COOKIE_NAME } from '../constants';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const changeCasing = (
  obj: Record<string, unknown>,
  modifier: (string: string) => string
) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target)
        ? key
        : key == key.toUpperCase()
        ? modifier(key).toUpperCase()
        : modifier(key);
      result[camelKey] = isObject(value)
        ? changeCasing(value as Record<string, unknown>, modifier)
        : value;
    }
  );

type ErrorCode =
  | 'COMPLETED_PREVIEW'
  | 'DELETED_PREVIEW'
  | 'COMPLETED_BUY_ORDER'
  | 'INTERNAL_SERVER_ERROR'
  | 'INSUFFICIENT_FUNDS'
  | 'OBSOLETE_PREVIEW'
  | 'NOT_AVAILABLE'
  | 'PAYMENT_FAILED';

export interface GeneralErrorResponse {
  errorCode: ErrorCode;
  reason: string;
}

export interface FormError {
  parameter: string;
  reason: string;
  errorCode: string;
  localizedReason: string;
}
export interface FormErrorResponse {
  errorCode: 'FORM_VALIDATION_FAILED';
  formErrors: FormError[];
}

export type ErrorResponse = { tracingId?: string; statusCode?: string } & (
  | GeneralErrorResponse
  | FormErrorResponse
);

type RequestOptions = Omit<RequestInit, 'body'> & {
  body?: Record<string, unknown>;
};

const pushDeepLink = () => {
  const currentDeeplinks: string[] = JSON.parse(
    window.sessionStorage.getItem(DEEPLINK_STORAGE_KEY) ?? '[]'
  );
  window.sessionStorage.setItem(
    DEEPLINK_STORAGE_KEY,
    JSON.stringify([
      ...currentDeeplinks,
      window.location.pathname + window.location.search + window.location.hash,
    ])
  );
};

/**
 * A wrapper function for making API calls
 * Can be use to modify the fetch requests, modify headers etc etc globally
 * We can add a second optional argument options of type object to achieve the same
 *
 * In the case of an error the BE will response it will either:
 * - ErrorResponse as above. Then it's a "known" error in the BE.
 * - Not Json, empty json or a json with {details}.
 *
 * For the latter case it's a more internal error and we don't want to expose
 * that to the end user.
 *
 * We always have snake_case in the BE and camelCase in the FE. Hence we need to
 * change the casing.
 */
export const fetchAPI = async <T>(
  url: string,
  options?: RequestOptions
): Promise<T> => {
  let data = null;
  let status = null;

  try {
    const response = await fetch(`${BASE_URL ? BASE_URL + '/' : ''}${url}`, {
      ...options,
      body: options?.body
        ? JSON.stringify(changeCasing(options.body, snakeCase))
        : undefined,
      headers: {
        ...options?.headers,
        'Content-Type': 'application/json',
        'Accept-Language': getLocaleFromStorage(),
        'x-csrftoken': Cookies.get('appapi-csrftoken') ?? '',
      },
      credentials: 'include',
    });
    status = response.status;
    if (response.status === 204) {
      return null as unknown as T;
    }
    data = changeCasing(await response.json(), camelCase);
    if (response.ok) {
      return data as T;
    }
    if (status === 401) {
      pushDeepLink();
      if (window.location.pathname.includes('/top-up')) {
        window.location.replace('/top-up/login' + location.search);
      } else if (window.location.pathname.includes('/processing')) {
        window.location.replace('/processing/login' + location.search);
      } else {
        window.location.replace('/login' + location.search);
      }
    }
    if (status === 403 && 'kyc' in data) {
      pushDeepLink();
      Cookies.set(KYC_COOKIE_NAME, JSON.stringify(data.kyc));
      window.location.assign('/onboarding/steps');
    }
    if (!('errorCode' in data)) throw Error('Generic error');
  } catch (exception) {
    console.error('fetchAPI exception', exception);
    throw {
      errorCode: 'INTERNAL_SERVER_ERROR',
      reason: 'A server error occurred.',
      statusCode: `${status}`,
    } as ErrorResponse;
  }
  if (!('localizedReason' in data)) {
    data['localizedReason'] = data.errorCode;
  }
  throw data as unknown as ErrorResponse;
};
