import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useMount, useSessionStorage } from 'react-use';
import { DEEPLINK_STORAGE_KEY } from '../constants';
interface DeepLinkContext {
  links: string[];
  update: (deepLinks: string[]) => void;
  push: () => void;
  pop: () => void;
}

const Context = createContext<DeepLinkContext>({
  update: () => ({}),
  push: () => ({}),
  pop: () => ({}),
  links: [],
});

export const DeepLinkProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [sessionDeepLinks, setSessionDeepLinks] = useSessionStorage<string[]>(
    DEEPLINK_STORAGE_KEY,
    []
  );

  const [links, setDeepLinks] = useState<string[]>([]);
  const location = useLocation();

  const updateDeepLinks = useCallback(
    (deepLinks: string[]) => {
      setDeepLinks(deepLinks);
      setSessionDeepLinks(deepLinks);
    },
    [setSessionDeepLinks]
  );

  const pushDeepLink = useCallback(() => {
    const deepLink = `${location.pathname}${location.search}`;
    updateDeepLinks([...links, deepLink]);
  }, [updateDeepLinks, links, location]);

  const popAndNavigate = useCallback(() => {
    const current = links.pop();
    if (!current) {
      throw Error('Navigating with no deeplinks.');
    }
    updateDeepLinks(links);
    navigate(current);
  }, [links, navigate, updateDeepLinks]);

  useMount(() => {
    if (sessionDeepLinks.length !== 0) {
      setDeepLinks(sessionDeepLinks);
    }
  });

  const value = useMemo(
    () => ({
      links,
      update: updateDeepLinks,
      push: pushDeepLink,
      pop: popAndNavigate,
    }),
    [links, updateDeepLinks, pushDeepLink, popAndNavigate]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDeepLink = (): {
  current: string | undefined;
  redirectToDeeplink: () => void;
} => {
  const { links, pop } = useContext(Context);

  const current = useMemo(
    () => (links.length > 0 ? links[links.length - 1] : undefined),
    [links]
  );
  return {
    current,
    redirectToDeeplink: pop,
  };
};

export const useSetDeepLinks = () => {
  return useContext(Context).update;
};

export const RedirectWithReturnLink = ({ to }: { to: string }) => {
  const { push } = useContext(Context);
  const location = useLocation();
  useMount(() => {
    push();
  });
  return <Navigate to={to} state={{ from: location }} replace />;
};
