/** @jsxImportSource @emotion/react */
import {
  useTheme,
  Typography,
  Button,
  createStyles,
  Money,
  MoneyScaled,
  removeScaleFromMoney,
} from '@quickbit/qb-design-system';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { useFormatCurrency, useConvertAmount, ConversionConfig } from 'hooks';

export interface Props {
  value: Money;
  onChange: (val: Money) => void;
  onMax?: () => void;
  disabled?: boolean;
  config: ConversionConfig;
  availableAmount?: MoneyScaled;
}

export const CurrencyAmountInput = ({
  value,
  onChange,
  onMax,
  disabled,
  config,
  availableAmount,
}: Props) => {
  const { palette, spacing } = useTheme();
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useIntl();
  const formatCurrency = useFormatCurrency();
  const convert = useConvertAmount(config);
  const [valueLength, setValueLength] = useState(0);
  const [available, setAvailable] = useState<MoneyScaled>();

  useMount(async () => {
    if (inputRef != null && inputRef.current) {
      inputRef.current.focus();
    }
  });

  const handleCurrencyChange = useCallback(() => {
    const convertedAmount = convert(value);
    onChange(convertedAmount);
    setValueLength(value.amount && convertedAmount.amount.toString().length);
  }, [onChange, convert, value]);

  const handleInputChange = (val: string) => {
    if (available) {
      const maxAmount = removeScaleFromMoney(available);
      if (Number(val) > maxAmount) val = maxAmount.toString();
    }
    setValueLength(val.length);
    onChange({ ...value, amount: +val >= 0 && !isNaN(+val) ? Number(val) : 0 });
  };

  useEffect(() => {
    if (!availableAmount) return;
    const update = async () => {
      if (value.currencyCode === availableAmount?.currencyCode) {
        setAvailable(availableAmount);
      } else {
        const convertedAmount = await convert({
          amount: removeScaleFromMoney(availableAmount),
          currencyCode: availableAmount.currencyCode,
        });
        setAvailable({ ...convertedAmount, scale: 0 });
      }
    };
    update();
  }, [availableAmount, convert, onChange, value.currencyCode]);

  return (
    <>
      <div
        css={[styles.root, disabled && styles.disabled]}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div css={styles.iconContainer}>
          {onMax && (
            <div css={styles.max} onClick={onMax}>
              <Typography
                variant="bodySmallestMedium"
                color={palette.grey.default}
              >
                {formatMessage({ id: 'max' })}
              </Typography>
              <Button variant="icon" />
            </div>
          )}
          {!onMax && <div css={{ width: spacing.s }} />}
        </div>
        <div css={styles.amountContainer}>
          <div>
            <input
              css={[
                styles.input,
                {
                  width: `calc(${valueLength}ch + ${spacing.xxs})`,
                },
              ]}
              value={valueLength === 0 ? '' : value.amount}
              onChange={(e) => handleInputChange(e.target.value)}
              disabled={disabled}
              type="number"
              ref={inputRef}
              min={0}
              data-testid="currency-amount-input"
            />
          </div>
          <Typography variant="subtitle" color={palette.grey.x500}>
            {value.currencyCode}
          </Typography>
        </div>
        <div css={styles.iconContainer}>
          <Button
            variant="icon"
            icon="switch-currency"
            onClick={handleCurrencyChange}
          />
        </div>
      </div>
      {available && (
        <div css={styles.balance}>
          <Typography variant="bodySmall" color={palette.grey.default}>
            {formatMessage(
              { id: 'input.availableAmount' },
              {
                amount: <strong>{formatCurrency(available)}</strong>,
              }
            )}
          </Typography>
        </div>
      )}
    </>
  );
};

const useStyles = createStyles(({ typography, palette, sizing, spacing }) => ({
  root: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'auto 1fr auto',
    gap: spacing.xxxs,
  },
  amountContainer: { display: 'flex', justifyContent: 'center' },
  input: {
    maxWidth: `calc(${sizing.small} - ${spacing.xl})`,
    fontSize: `${typography.heading1.fontSize} !important`,
    fontWeight: `${typography.heading1.fontWeight} !important`,
    lineHeight: `${typography.heading1.lineHeight} !important`,
    letterSpacing: `${typography.heading1.letterSpacing} !important`,
    textAlign: 'right',
    padding: '0 4px 0 0',
    MozAppearance: 'textfield',
    '::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    border: 'none',
    background: 'inherit',
    ':focus': {
      outline: 'none',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    svg: {
      color: palette.grey.default,
    },
  },
  max: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: {
      position: 'absolute',
      zIndex: 10,
    },
  },
  disabled: {
    opacity: '0.5',
    pointerEvents: 'none',
  },

  balance: { textAlign: 'center', marginTop: spacing.xxxs },
}));
