/** @jsxImportSource @emotion/react */
import { Loader, createStyles } from '@quickbit/qb-design-system';

interface Props {
  /** Set position of loader for desktop view only, defaults to `center`
   */
  position?: 'left';
}

export const QBLoader = ({ position }: Props) => {
  const styles = useStyles();
  return (
    <div
      css={[
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          inset: 0,
          maxWidth: '100% !important',
        },
        position && styles[position],
      ]}
    >
      <Loader />
    </div>
  );
};

const useStyles = createStyles(({ sizing, breakpoints }) => ({
  left: {
    img: {
      [breakpoints.laptopSmall]: {
        marginRight: sizing.medium,
      },
    },
  },
}));
