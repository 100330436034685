/* eslint-disable no-restricted-properties */
import { type OpenWindowOptions } from 'types';

export const getConfig = () => ({
  openWindow: (ref: string, options?: OpenWindowOptions) => {
    const target = options?.replaceCurrentWindow ? '_self' : '_blank';

    return window.open(ref, target) as Window;
  },
  closeWindow: (window: Window) => window.close(),
});
