import { useCallback, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  useAsync,
  useLocalStorage,
  useMount,
  useUpdateEffect,
} from 'react-use';
import { v4 as uuid } from 'uuid';
import { useConfig, useSetSession } from 'context';
import { useLoginCountry, useTokenPolling } from 'hooks';
import { inIFrame } from 'utils';
import { EidProviderName } from '../../types';
import {
  getAppCountries,
  initiateEIDLogin,
  loginStatus,
  SignInSuccessData,
} from './api';

export interface InitEidArgs {
  provider: EidProviderName;
}

/**
 * Login using EID token
 */
export const useEidLogin = ({
  success,
  userId,
}: {
  success: (data: SignInSuccessData) => void;
  userId?: string;
}) => {
  const [deviceId] = useLocalStorage<string>('qb_web_deviceId', uuid());
  const { loginCountry, changeLoginCountry } = useLoginCountry();
  const [window, setWindow] = useState<Window | void>();
  const [fetchingAuthData, setFetchingAuthData] = useState(false);
  const [authData, setAuthData] = useState<{
    url: string;
    token: string;
  }>();

  const { openWindow, closeWindow } = useConfig();
  const setSession = useSetSession();
  const [params, setParams] = useSearchParams();
  const location = useLocation();

  const tokenSearchParam = params.get('token');
  const [paramsToken, setParamsToken] = useState<string | null>(
    tokenSearchParam
  );
  const [rememberMe, setRememberMe] = useState(true);

  const { value: appCountries, loading: loadingCountries } = useAsync(
    async () => (await getAppCountries()).countries
  );

  const selectedCountry = useMemo(
    () => appCountries?.find((country) => country.code === loginCountry),
    [appCountries, loginCountry]
  );

  const auth = useCallback(
    // Will add more provides per country later
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (input?: InitEidArgs) => {
      if (paramsToken) return { token: paramsToken };

      if (!selectedCountry) {
        console.error('Cannot init EidLogin without args');
        throw Error('Cannot init EidLogin without args');
      }

      const response = await initiateEIDLogin({
        userId,
        rememberMe,
        deviceId: deviceId as string,
        nationality: selectedCountry.code,
        providerName: selectedCountry.auth[0].provider,
        redirectUrl: inIFrame() ? undefined : location.pathname,
      });

      const { url, token } = response;
      setAuthData({ url, token });

      return response;
    },
    [
      deviceId,
      userId,
      paramsToken,
      setAuthData,
      location.pathname,
      rememberMe,
      selectedCountry,
    ]
  );

  const poll = (eidToken: string) => loginStatus(eidToken);

  const handleSuccess = useCallback(
    (data: SignInSuccessData) => {
      if (closeWindow && window) closeWindow(window);
      setSession(
        {
          firstOnboardingCompleted: data.firstOnboardingCompleted,
          email: data.kyc.email?.address,
          fullName: data.kyc.firstName + ' ' + data.kyc.lastName,
        },
        data.tokenTtlInSeconds
      );
      success(data);
    },
    [closeWindow, setSession, success, window]
  );

  const {
    start: startPolling,
    cancel: cancelPolling,
    polling: isPollingLogin,
    error: pollingError,
  } = useTokenPolling<InitEidArgs, SignInSuccessData>({
    initiate: async () => {
      if (!authData) {
        if (!paramsToken) {
          console.error('Cannot initiate polling without auth token');
          throw Error('Cannot initiate polling without auth token');
        }
        return { token: paramsToken };
      }
      return { token: authData.token };
    },
    poll,
    success: handleSuccess,
  });

  const cancel = useCallback(() => {
    if (authData && selectedCountry) {
      setAuthData(undefined);
      auth();
    }
    setParamsToken(null);
    cancelPolling();
  }, [cancelPolling, authData, auth, selectedCountry]);

  useUpdateEffect(() => {
    if (selectedCountry) {
      setFetchingAuthData(true);
      auth().then(() => setFetchingAuthData(false));
    } else {
      setAuthData(undefined);
    }
  }, [auth, selectedCountry, rememberMe, pollingError]);

  useUpdateEffect(() => {
    if (pollingError && paramsToken) {
      setParamsToken(null);
      params.delete('token');
      setParams(params);
    }
  }, [pollingError, paramsToken]);

  const start = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (input?: InitEidArgs) => {
      //authData can be undefined initially
      if (authData) {
        setWindow(
          openWindow(authData.url, {
            replaceCurrentWindow: !inIFrame(),
          })
        );
      }
      if (inIFrame()) {
        startPolling();
      }
    },
    [authData, openWindow, startPolling]
  );

  // This will start polling immediately for when users are redirected back
  // from the EID application
  useMount(() => {
    if (paramsToken) startPolling();
  });

  return {
    start,
    cancel,
    isPollingLogin,
    fetchingAuthData,
    pollingError,
    loadingCountries,
    appCountries,
    rememberMe,
    setRememberMe,
    changeLoginCountry,
    selectedCountry,
  };
};
