import * as mixpanelBrowser from 'mixpanel-browser';
import {
  createContext,
  type ProviderProps,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { useMount } from 'react-use';

type MixpanelContext = mixpanelBrowser.Mixpanel | undefined;
type UseMixpanelReturnType = {
  mixpanel: mixpanelBrowser.Mixpanel;
  mixpanelTrackEvent: (
    eventName: string,
    properties?: mixpanelBrowser.Dict
  ) => void;
};

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

// Custom options to pass to `mixpanel.init()`.
export const MIXPANEL_CONFIG = {
  api_host: 'https://api-eu.mixpanel.com',
  track_pageview: false,
  ignore_dnt: true,
  opt_out_tracking_by_default: false,
  debug: false,
};

export const mixpanelContext = createContext<MixpanelContext>(undefined);

export interface MixpanelProviderProps
  extends Omit<ProviderProps<MixpanelContext>, 'value'> {
  token?: string;
  config?: Partial<mixpanelBrowser.Config>;
  name?: string;
}

export function MixpanelProvider({
  token = MIXPANEL_TOKEN,
  config: _config = MIXPANEL_CONFIG,
  name: _name,
  children,
}: MixpanelProviderProps) {
  // Name is used to identify the mixpanel instance in the global scope
  const name = useMemo(() => _name ?? 'app-mixpanel-browser', [_name]);

  const config = useMemo(
    () => ({
      track_pageview: false, // Rarely makes sense to track page views in React apps
      ..._config,
    }),
    [_config]
  );

  // If `token` is `undefined` or otherwise falsey then `useMixpanel()` will return
  // `undefined` which can be useful for environments where Mixpanel integration is not desired.
  const context = useMemo(
    () => (token ? mixpanelBrowser.init(token, config, name) : undefined),
    [config, name, token]
  );

  return (
    <mixpanelContext.Provider value={context}>
      {children}
    </mixpanelContext.Provider>
  );
}

export const useMixpanel = (
  superProperties?: Record<string, string | string[]>
): UseMixpanelReturnType => {
  const mixpanel = useContext(mixpanelContext);

  if (mixpanel === undefined) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }

  useMount(() => {
    if (superProperties) {
      mixpanel.register(superProperties);
    }
  });

  const mixpanelTrackEvent: UseMixpanelReturnType['mixpanelTrackEvent'] =
    useCallback(
      (eventName, properties) => {
        mixpanel.track(eventName, properties);
      },
      [mixpanel]
    );

  return { mixpanel, mixpanelTrackEvent };
};
