import { useCallback } from 'react';
import { useCookies as reactUseCookies } from 'react-cookie';
import { Cookie, CookieSetOptions } from 'universal-cookie';

export const getExpirationDate = (expSeconds = 60 * 60 * 24): Date => {
  const date = new Date();
  date.setTime(date.getTime() + expSeconds * 1000);
  return date;
};

type UseCookiesReturnType = [
  Record<string, Cookie>,
  (value: Record<string, Cookie>, options?: CookieSetOptions) => void,
  (options?: CookieSetOptions) => void
];

const defaultOptions = {
  path: '/',
  expires: getExpirationDate(30 * 24 * 60 * 60), // 30 days
};

/**
 *
 * @param cookieName specifies the cookie name your component depend on or that should trigger a re-render.
 *
 * `cookieName` will be used to set and remove cookies as well.
 * @returns {Array} UseCookiesReturnType
 */
export const useCookies = (cookieName: string): UseCookiesReturnType => {
  const [allCookies, set, remove] = reactUseCookies([cookieName]);
  const cookie = allCookies[cookieName];

  const setCookie = useCallback(
    (value: Record<string, Cookie>, options?: CookieSetOptions) => {
      set(cookieName, value, { ...defaultOptions, ...options });
    },
    [set, cookieName]
  );

  const removeCookie = useCallback(
    (options?: CookieSetOptions) => {
      remove(cookieName, options);
    },
    [remove, cookieName]
  );

  return [cookie, setCookie, removeCookie];
};
