/** @jsxImportSource @emotion/react */
import { DesignSystemProvider } from '@quickbit/qb-design-system';
import { Fragment, lazy, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { getMicroArticles } from 'api/contentful/getMicroArticles';
import { ProtectedRoute, NavbarLayout, QBLoader } from 'components';
import { WalletsProvider, ConfigProvider } from 'context';
import { useLocale } from 'hooks';
import { TopUpCancelled } from 'top-up-pages';
import { Locale, type Config } from 'types';
import { renderText, getMessages } from 'utils';
import { lazily } from 'utils/lazily';

const {
  Home,
  Buy: BuyPullup,
  Send: SendPullup,
  Receive: ReceivePullup,
  Swap: SwapPullup,
  Sell: SellPullup,
  OpenEuroAccountIntro,
  OpenEuroAccountSuccess,
} = lazily(() => import('pages/Home'));

const {
  OnboardingLayout,
  Kyc,
  EmailVerification,
  PhoneVerification,
  ContactInformation,
  SourceOfFunds,
  Steps,
  Consent,
} = lazily(() => import('pages/Onboarding'));

const {
  OnboardingLayout: ProcessingOnboardingLayout,
  Login: ProcessingLogin,
  Success: ProcessingSuccess,
  Failure: ProcessingFailure,
  Steps: ProcessingSteps,
  ProcessingContextProvider,
} = lazily(() => import('processing-pages'));

const AccountOnHold = lazy(() => import('pages/AccountOnHold'));
const AccountBlocked = lazy(() => import('pages/AccountBlocked'));
const AccountClosed = lazy(() => import('pages/AccountClosed'));

const AppDownload = lazy(() => import('pages/AppDownload'));

const Support = lazy(() => import('pages/Support'));

const SetPassword = lazy(() => import('pages/Onboarding/SetPassword'));

const { ForgotPassword, Login, EmailLogin } = lazily(
  () => import('pages/Login')
);

const NotFound = lazy(() => import('pages/NotFound'));
const FeatureFlags = lazy(() => import('pages/FeatureFlags'));

const {
  WalletOutlet,
  Wallet,
  BuyOutlet,
  BuyInputAmount,
  BuyPreview,
  BuyProcessing,
  Success: BuySuccess,
  SendOutlet,
  Destination: SendDestination,
  CreateRecipient,
  SendInputAmount,
  SendPreview,
  Processing: SendProcessing,
  UpdateRecipient,
  Receive,
  SwapOutlet,
  Swap,
  SwapInputAmount,
  SwapPreview,
  SwapProcessing,
  Deposit,
  TransferOutlet,
  TransferInputAmount,
  TransferPreview,
  TransferSuccess,
  Sell,
  SellInputAmount,
  SellOutlet,
  SellPreview,
  SellProcessing,
  TransactionDetails,
} = lazily(() => import('pages/Wallet'));

const {
  Profile,
  AccountInformation,
  TermsAndPolicies,
  GDPR,
  Consent: ProfileConsent,
  ChangePassword,
  ProfileOutlet,
} = lazily(() => import('pages/Profile'));

const {
  TopUpSendPreview,
  TopUpSendProcessing,
  TopUpBuy,
  TopUpOutlet,
  TopUpOnboardingLayout,
  TopUpOnboardingSteps,
  TopUpLogin,
  TopUpFailure,
  TopUpEmailLogin,
  TopUpSuccess,
  TopUpCashierBuy,
  TopUpCompleted,
} = lazily(() => import('top-up-pages'));

const { PasswordReset } = lazily(() => import('pages/PasswordReset'));

interface Props {
  config: Config;
}

const microArticleLocale: Record<string, Locale> = {
  //since we don't have translations for Norway on contentful so using 'en-US'
  'en-US': 'en-US',
  nn: 'en-US',
  sv: 'sv',
};

const App = ({ config }: Props) => {
  const { locale } = useLocale();

  const { value: content, loading } = useAsync(async () => {
    const microArticles = await getMicroArticles(microArticleLocale[locale]);
    return { ...getMessages(locale), ...microArticles };
  }, [locale]);

  if (loading) return null;

  return (
    <ConfigProvider config={config}>
      <IntlProvider
        locale={locale}
        messages={content}
        textComponent={({ children }: { children?: string[] }) => {
          if (!children) return null;
          return (
            <Fragment>
              {renderText(Array.isArray(children) ? children[0] : children)}
            </Fragment>
          );
        }}
      >
        <DesignSystemProvider>
          <Suspense fallback={<QBLoader />}>
            <Routes>
              {/* public APP routes */}
              <Route path="/login" element={<Login />}>
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="email" element={<EmailLogin />} />
              </Route>
              <Route path="/account-on-hold" element={<AccountOnHold />} />
              <Route path="/account-blocked" element={<AccountBlocked />} />
              <Route path="/account-closed" element={<AccountClosed />} />
              <Route path="/support" element={<Support />} />
              <Route path="/app-download" element={<AppDownload />} />
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/feature-flags" element={<FeatureFlags />} />

              {/* protected APP routes */}
              <Route element={<ProtectedRoute redirectUrl="/login" />}>
                <Route path="onboarding" element={<OnboardingLayout />}>
                  <Route path="set-password" element={<SetPassword />} />
                  <Route path="consent" element={<Consent />} />
                  <Route path="steps" element={<Steps />} />
                  <Route
                    path="email-verification"
                    element={<EmailVerification />}
                  />
                  <Route
                    path="phone-verification"
                    element={<PhoneVerification />}
                  />
                  <Route
                    path="contact-information"
                    element={<ContactInformation />}
                  />
                  <Route path="source-of-funds" element={<SourceOfFunds />} />
                  <Route path="kyc" element={<Kyc />}>
                    <Route path=":questionCode" element={<Kyc />} />
                  </Route>
                </Route>
                <Route path="/not-found" element={<NotFound />} />
                <Route element={<AppLayout />}>
                  <Route path="/" element={<Home />}>
                    <Route path="buy" element={<BuyPullup />} />
                    <Route path="send" element={<SendPullup />} />
                    <Route path="receive" element={<ReceivePullup />} />
                    <Route path="swap" element={<SwapPullup />} />
                    <Route path="sell" element={<SellPullup />} />
                    <Route path="euro-account">
                      <Route index element={<OpenEuroAccountIntro />} />
                      <Route
                        path="approved"
                        element={<OpenEuroAccountSuccess />}
                      />
                      <Route
                        path="kyc/:questionCode"
                        element={<OpenEuroAccountIntro />}
                      />
                    </Route>
                  </Route>
                  <Route path=":currencyCode" element={<WalletOutlet />}>
                    <Route path="" element={<Wallet />}>
                      <Route path="buy" element={<BuyOutlet />}>
                        <Route
                          index
                          element={<Navigate to="input-amount" replace />}
                        />
                        <Route
                          path="input-amount"
                          element={<BuyInputAmount />}
                        />
                        <Route path="preview" element={<BuyPreview />} />
                        <Route path="processing" element={<BuyProcessing />} />
                        <Route path="success" element={<BuySuccess />} />
                      </Route>
                      <Route path="send" element={<SendOutlet />}>
                        <Route
                          index
                          element={<Navigate to="destination" replace />}
                        />
                        <Route
                          path="destination"
                          element={<SendDestination />}
                        />
                        <Route
                          path="create-recipient"
                          element={<CreateRecipient />}
                        />
                        <Route
                          path="update-recipient"
                          element={<UpdateRecipient />}
                        />
                        <Route
                          path="input-amount"
                          element={<SendInputAmount />}
                        />
                        <Route path="preview" element={<SendPreview />} />
                        <Route path="processing" element={<SendProcessing />} />
                      </Route>
                      <Route path="receive" element={<Receive />} />
                      <Route path="swap" element={<SwapOutlet />}>
                        <Route index element={<Swap />} />
                        <Route
                          path="input-amount"
                          element={<SwapInputAmount />}
                        />
                        <Route path="preview" element={<SwapPreview />} />
                        <Route path="processing" element={<SwapProcessing />} />
                      </Route>
                      <Route path="sell" element={<SellOutlet />}>
                        <Route index element={<Sell />} />
                        <Route
                          path="input-amount"
                          element={<SellInputAmount />}
                        />
                        <Route path="preview" element={<SellPreview />} />
                        <Route path="processing" element={<SellProcessing />} />
                      </Route>
                      <Route path="deposit" element={<Deposit />} />
                      <Route path="transfer" element={<TransferOutlet />}>
                        <Route
                          index
                          element={<Navigate to="input-amount" replace />}
                        />
                        <Route
                          path="input-amount"
                          element={<TransferInputAmount />}
                        />
                        <Route path="preview" element={<TransferPreview />} />
                        <Route path="success" element={<TransferSuccess />} />
                      </Route>
                      <Route
                        path="transaction-details/:transactionId"
                        element={<TransactionDetails />}
                      />
                    </Route>
                  </Route>

                  <Route path="profile" element={<ProfileOutlet />}>
                    <Route index element={<Profile />} />
                    <Route
                      path="account-information"
                      element={<AccountInformation />}
                    />
                    <Route
                      path="change-password"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="terms-and-policies"
                      element={<TermsAndPolicies />}
                    />
                    <Route path="gdpr" element={<GDPR />} />
                    <Route path="consent" element={<ProfileConsent />} />
                  </Route>
                </Route>
              </Route>

              <Route
                path="processing"
                element={
                  <ProcessingContextProvider>
                    <Outlet />
                  </ProcessingContextProvider>
                }
              >
                {/* public Processing routes */}
                <Route path="login" element={<ProcessingLogin />}>
                  <Route path="forgot-password" element={<ForgotPassword />} />
                </Route>
                <Route path="failure" element={<ProcessingFailure />} />
                {/* protected Processing routes */}
                <Route
                  element={<ProtectedRoute redirectUrl="/processing/login" />}
                >
                  <Route path="success" element={<ProcessingSuccess />} />
                  <Route
                    path="onboarding"
                    element={<ProcessingOnboardingLayout />}
                  >
                    <Route path="set-password" element={<SetPassword />} />
                    <Route path="steps" element={<ProcessingSteps />} />
                    <Route
                      path="email-verification"
                      element={<EmailVerification />}
                    />
                    <Route
                      path="phone-verification"
                      element={<PhoneVerification />}
                    />
                    <Route
                      path="contact-information"
                      element={<ContactInformation />}
                    />
                    <Route path="kyc" element={<Kyc />}>
                      <Route path=":questionCode" element={<Kyc />} />
                    </Route>
                    <Route path="source-of-funds" element={<SourceOfFunds />} />
                  </Route>
                </Route>
              </Route>

              <Route path="top-up" element={<TopUpOutlet />}>
                {/* public TopUp routes */}
                <Route path="login" element={<TopUpLogin />}>
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="email" element={<TopUpEmailLogin />} />
                </Route>
                <Route path="account-on-hold" element={<AccountOnHold />} />
                <Route path="account-blocked" element={<AccountBlocked />} />
                <Route path="account-closed" element={<AccountClosed />} />
                {/* protected TopUp routes */}
                <Route element={<ProtectedRoute redirectUrl="/top-up/login" />}>
                  <Route path="failure" element={<TopUpFailure />} />
                  <Route path="onboarding" element={<TopUpOnboardingLayout />}>
                    <Route path="set-password" element={<SetPassword />} />
                    <Route path="consent" element={<Consent />} />
                    <Route path="steps" element={<TopUpOnboardingSteps />} />
                    <Route
                      path="email-verification"
                      element={<EmailVerification />}
                    />
                    <Route
                      path="phone-verification"
                      element={<PhoneVerification />}
                    />
                    <Route
                      path="contact-information"
                      element={<ContactInformation />}
                    />
                    <Route path="kyc" element={<Kyc />}>
                      <Route path=":questionCode" element={<Kyc />} />
                    </Route>
                    <Route path="source-of-funds" element={<SourceOfFunds />} />
                  </Route>
                  <Route index element={<TopUpSendPreview />} />
                  <Route path="processing" element={<TopUpSendProcessing />} />
                  <Route path="buy">
                    <Route index element={<TopUpBuy />} />
                    <Route path="cashier" element={<TopUpCashierBuy />} />
                  </Route>
                  <Route path="success" element={<TopUpSuccess />} />
                  <Route path="completed" element={<TopUpCompleted />} />
                  <Route path="cancelled" element={<TopUpCancelled />} />
                </Route>
              </Route>
              {/* catch all non existing routes */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </DesignSystemProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

const AppLayout = () => {
  return (
    <WalletsProvider>
      <NavbarLayout>
        <Outlet />
      </NavbarLayout>
    </WalletsProvider>
  );
};

export default App;
