import React from 'react';
import { useIntl } from 'react-intl';
import { Markdown } from './Markdown';
import { PullUpModal } from './PullUpModal';

interface Props {
  articleKey: string;
  onClose: () => void;
}

export const MicroArticlePullUpModal = ({ articleKey, onClose }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <PullUpModal
      title={formatMessage({ id: `${articleKey}.title` })}
      onClose={onClose}
    >
      <Markdown markdown={formatMessage({ id: `${articleKey}.body` })} />
    </PullUpModal>
  );
};
