import { MoneyScaled } from '@quickbit/qb-design-system';
import { fetchAPI } from 'api';
import { Wallet } from 'types';

export interface GetWalletsResponse {
  accounts: Wallet[];
}

interface Merchant {
  name: string;
  uuid: string;
}

interface ReceiveOrderDetails {
  amount: MoneyScaled;
  merchant: Merchant;
  redirectUrl?: string;
  failureRedirectUrl?: string;
}

export const getWallets = async () => {
  return fetchAPI<GetWalletsResponse>(`accounts`);
};

export const getReceiveOrderDetails = async (receiveOrderPreviewId: string) => {
  return fetchAPI<ReceiveOrderDetails>(
    `receive/orders/${receiveOrderPreviewId}`
  );
};
