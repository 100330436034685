import { FeatureFlagValues } from './constants';

// Store overrides in local storage
const localStorageKeyOverrides = 'quickbitFeatureFlagOverrides';
export const getFeatureFlagOverrides = (): Record<string, boolean> => {
  if (typeof window === 'undefined' || !localStorage) {
    return {};
  }
  const valueFromStorage = localStorage.getItem(localStorageKeyOverrides);
  if (valueFromStorage) {
    const value = JSON.parse(valueFromStorage);
    if (value) {
      return value;
    }
  }
  return {};
};

export const setFeatureFlagOverride = (
  flag: FeatureFlagValues,
  newValue: boolean
) => {
  const overrides = getFeatureFlagOverrides();
  overrides[flag] = newValue;
  localStorage.setItem(localStorageKeyOverrides, JSON.stringify(overrides));
  window.location.reload();
};

export const clearFeatureFlagOverrides = () => {
  localStorage.setItem(localStorageKeyOverrides, '');
  window.location.reload();
};

// Store device specific random value in local storage
const localStorageKeyDeviceValue = 'quickbitFeatureFlagRandomDeviceValue';

export const getDeviceSpecificRandomValue = (): number => {
  // We have seen errors where localStorage is not available
  if (!localStorage) {
    return 0;
  }
  const valueFromStorage = localStorage.getItem(localStorageKeyDeviceValue);
  if (valueFromStorage) {
    const value = Number(valueFromStorage);
    if (value) {
      return value;
    }
  }
  // Nothing in storage, generate new random value and store on device
  const randomValue = Math.random();
  setDeviceSpecificRandomValue(randomValue);
  return randomValue;
};

export const setDeviceSpecificRandomValue = (num: number) => {
  localStorage.setItem(localStorageKeyDeviceValue, String(num));
};
