/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { ErrorComponent } from 'components';
import { useMixpanel, useSession } from 'context';
import { useLocalStorage, useWillMount } from 'hooks';
import { getReceiveOrderDetails } from 'top-up-pages/api';
import { MoneyScaled } from 'types';

const LOCAL_STORAGE_KEY = 'qb_web_static_topUpData';
const EDITABLE_LOCAL_STORAGE_KEY = 'qb_web_editable_topUpData';

interface EditableTopUpData {
  cryptoAmount?: MoneyScaled;
  previewId?: string;
  buyPreviewId?: string;
  shouldSendAutomatically?: boolean;
}

type StaticTopUpData = {
  receiveOrderPreviewId: string;
  existingUser: boolean;
  email?: string;
  countryCode?: string;
  redirectUrl?: string;
  failureRedirectUrl?: string;
  hasCardData?: boolean;
  merchantName?: string;
  merchantId?: string;
  fiatAmount?: MoneyScaled;
};

export type TopUpData = StaticTopUpData & EditableTopUpData;

type TopUpProviderValue = TopUpData & {
  setTopUpData: (topUpData: Partial<TopUpData>) => void;
};

const TopUpContext = createContext<TopUpProviderValue>({
  receiveOrderPreviewId: '',
  existingUser: false,
  setTopUpData: () => undefined,
});

export const useSetTopUpData = (): ((data: Partial<TopUpData>) => void) =>
  useContext(TopUpContext).setTopUpData;

export const useTopUpData: () => TopUpData = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setTopUpData, ...rest } = useContext(TopUpContext);
  return rest;
};

export const TopUpProvider = ({ children }: { children: JSX.Element }) => {
  const session = useSession();
  const { mixpanelTrackEvent, mixpanel } = useMixpanel({
    Product: 'First Topup',
    Page: 'Provider',
  });
  const [params] = useSearchParams();
  const staticDataFromParams: StaticTopUpData = {
    receiveOrderPreviewId: params.get('receiveOrderPreviewId') || '',
    email: params.get('email') || undefined,
    existingUser: params.get('existingUser') === 'true',
    hasCardData: params.get('hasCardData') === 'true',
    countryCode: params.get('countryCode') || undefined,
  };

  const [staticTopUpData, setStaticTopUpData] =
    useLocalStorage<StaticTopUpData>(LOCAL_STORAGE_KEY, staticDataFromParams);
  const [editableTopUpData, setEditableTopUpData] =
    useLocalStorage<EditableTopUpData>(EDITABLE_LOCAL_STORAGE_KEY, {});

  const [{ error }, resetTopUpData] = useAsyncFn(async () => {
    setStaticTopUpData({
      ...staticDataFromParams,
    });
    setEditableTopUpData({});
    const orderData = await getReceiveOrderDetails(
      staticDataFromParams.receiveOrderPreviewId
    );
    setStaticTopUpData((prevData) => ({
      ...prevData!,
      merchantName: orderData.merchant.name,
      merchantId: orderData.merchant.uuid,
      fiatAmount: orderData.amount,
      redirectUrl: orderData.redirectUrl,
      failureRedirectUrl: orderData.failureRedirectUrl,
    }));
  }, [setStaticTopUpData, staticDataFromParams]);

  useWillMount(() => {
    const paramReceiveId = staticDataFromParams.receiveOrderPreviewId;
    if (paramReceiveId) {
      // When receiveOrderPreviewId is present in the URL, we want to reset the top-up data
      resetTopUpData();
      mixpanel.register({
        receiveOrderPreviewId: paramReceiveId,
      });
    }
    if (staticDataFromParams.email) {
      mixpanel.alias(staticDataFromParams.email);
    }
    mixpanelTrackEvent('Topup started', {
      description: 'User has landed on the Topup page',
      wasLoggedIn: !!session,
    });
  });

  const value = useMemo(
    () => ({
      ...staticTopUpData!,
      ...editableTopUpData,
      setTopUpData: (data: Partial<EditableTopUpData>) =>
        setEditableTopUpData((prevState) => ({ ...prevState, ...data })),
    }),
    [staticTopUpData, editableTopUpData, setEditableTopUpData]
  );

  if (error) return <ErrorComponent error={error}></ErrorComponent>;

  return (
    <TopUpContext.Provider value={value}>{children}</TopUpContext.Provider>
  );
};
