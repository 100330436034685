import { useMount, useLocalStorage } from 'react-use';
import { Locale, QBPreferences } from 'types';
import { QB_PREFERENCES_STORAGE_KEY } from '../constants';

const defaultLocale: Locale = 'en-US';

type UseLocaleReturnType = {
  locale: Locale;
  changeLocale: (locale: Locale) => void;
};

export const useLocale = (): UseLocaleReturnType => {
  const [preferences, setPreferences] = useLocalStorage<QBPreferences>(
    QB_PREFERENCES_STORAGE_KEY
  );

  useMount(() => {
    if (preferences && !preferences.locale) {
      setPreferences({ ...preferences, locale: defaultLocale });
    }
  });

  const changeLocale = (locale: Locale) => {
    setPreferences({ ...preferences, locale });
  };

  return {
    locale: preferences?.locale || defaultLocale,
    changeLocale,
  };
};

export const getLocaleFromStorage = () => {
  const preferencesString = localStorage.getItem(QB_PREFERENCES_STORAGE_KEY);
  if (!preferencesString) {
    return defaultLocale;
  }

  const preferences = JSON.parse(preferencesString);

  if (!preferences.locale) {
    return defaultLocale;
  }

  return preferences.locale;
};
