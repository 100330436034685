import { fetchAPI } from 'api';
import { SendPreviewResponseBase } from '../Preview/api';

export type SendOrderPreviewResponse = SendPreviewResponseBase & {
  status: string;
};

export const sendOrder = async (previewId: string) => {
  return await fetchAPI<SendOrderPreviewResponse>('send/orders', {
    method: 'POST',
    body: {
      previewId,
    },
  });
};
