import { useCallback, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router';
import { OnboardingSchema } from 'pages/Onboarding/Kyc/api';
import { KycDispatchAction, KycFormAnswers } from 'types';
import { useQuestionNavigation } from '.';

export const useKycQuestions = (
  nextRouteBase: string,
  onboardingData?: OnboardingSchema
) => {
  const { questionCode: currentCode = '' } = useParams();
  const navigate = useNavigate();

  const [formAnswers, dispatch] = useReducer(
    (
      prevState: KycFormAnswers,
      { type, code, value }: KycDispatchAction
    ): KycFormAnswers => {
      switch (type) {
        case 'UPDATE': {
          return {
            ...prevState,
            [code]: Array.isArray(value) ? value : [value],
          };
        }
        case 'DELETE': {
          const newState = { ...prevState };
          delete newState[code];
          return {
            ...newState,
          };
        }
      }
    },
    {}
  );

  const { question, progress, getNextRoute } = useQuestionNavigation(
    currentCode,
    formAnswers,
    onboardingData
  );

  const goToNextPage = () => {
    const nextRoute = getNextRoute();
    if (nextRoute) navigate(`${nextRouteBase}/${nextRoute.code}`);
  };

  const goToPreviousPage = () => {
    dispatch({ type: 'DELETE', code: currentCode, value: [] });
    navigate(-1);
  };

  const handleAnswerChange = useCallback(
    (value: string | string[]) => {
      dispatch({ type: 'UPDATE', code: currentCode, value });
    },
    [currentCode]
  );

  return {
    question,
    progress,
    goToNextPage,
    goToPreviousPage,
    formAnswers,
    handleAnswerChange,
  };
};
