/** @jsxImportSource @emotion/react */
import {
  CheckboxGroup,
  RadioGroup,
  Typography,
  useTheme,
} from '@quickbit/qb-design-system';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { Question } from 'pages/Onboarding/Kyc/api';
import { ChoiceType, KycFormAnswers } from 'types';

interface Props {
  question: Question;
  formAnswers: KycFormAnswers;
  handleAnswerChange: (value: string | string[]) => void;
}

export const KycQuestions = ({
  question,
  formAnswers,
  handleAnswerChange,
}: Props) => {
  const { questionCode: currentCode = '' } = useParams();
  const { spacing, sizing } = useTheme();

  return (
    <>
      <div>
        <Typography variant="bodyBold">{question.localizedText}</Typography>
      </div>
      <div css={{ marginTop: spacing.xxxs }}>
        <Typography>
          <FormattedMessage
            id={
              question.choiceType === ChoiceType.SINGLE
                ? 'page.onboarding.singleChoice'
                : 'page.onboarding.multiChoice'
            }
          />
        </Typography>
      </div>
      <div css={{ marginTop: spacing.xs }}>
        {question.choiceType === ChoiceType.SINGLE && (
          <RadioGroup
            key={currentCode}
            options={question.choices.map((c) => ({
              value: c.value,
              label: c.localizedText,
            }))}
            onChange={(e) => handleAnswerChange(e.target.value)}
            value={
              (formAnswers[currentCode] && formAnswers[currentCode][0]) ?? ''
            }
          />
        )}

        {question.choiceType === ChoiceType.MULTIPLE && (
          <div
            css={{
              maxHeight: sizing.small,
              overflow: 'scroll',
            }}
          >
            <CheckboxGroup
              key={currentCode}
              options={question.choices
                .filter((c) => c.value !== 'ZZ')
                .map((c) => ({
                  value: c.value,
                  label: c.localizedText,
                }))}
              onChange={handleAnswerChange}
              values={formAnswers[currentCode] ? formAnswers[currentCode] : []}
            />
          </div>
        )}
      </div>
    </>
  );
};
