import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSessionStorage } from 'react-use';
import { useDeepLink, useKycData, useUpdateKycData } from 'context';
import { ActionKey } from 'types';

type OnboardingData = {
  emailActionRequired: boolean;
  requirePasswordInput: boolean;
  phoneActionRequired: boolean;
  addressActionRequired: boolean;
  kycActionRequired: boolean;
  sourceOfFundsRequired: boolean;
};

type OnboardingProviderValue = OnboardingData & {
  urls: OnboardingUrls;
  setOnboardingData: (onboardingData: Partial<OnboardingData>) => void;
  goToNextStep: () => void;
};

const ONBOARDING_STORAGE_KEY = 'qb_web_onboarding';

const OnboardingContext = createContext<OnboardingProviderValue>({
  emailActionRequired: false,
  requirePasswordInput: false,
  phoneActionRequired: false,
  addressActionRequired: false,
  kycActionRequired: false,
  sourceOfFundsRequired: false,
  urls: {
    STEPS: '',
    ADDRESS: '',
    EMAIL: '',
    PASSWORD: '',
    PHONE: '',
    ONBOARDING: '',
    SOURCE_OF_FUNDS: '',
    SUCCESS: '',
  },
  setOnboardingData: () => undefined,
  goToNextStep: () => undefined,
});

export const useSetOnboardingData = (): ((
  data: Partial<OnboardingData>
) => void) => useContext(OnboardingContext).setOnboardingData;

export const useOnboardingData: () => OnboardingData = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setOnboardingData, goToNextStep, ...rest } =
    useContext(OnboardingContext);
  return rest;
};

export const useGoToNextStep = () => {
  const data = useContext(OnboardingContext);
  return data.goToNextStep;
};
export const useUrls = () => {
  const data = useContext(OnboardingContext);
  return data.urls;
};

export type OnboardingUrls = Record<ActionKey | 'STEPS' | 'SUCCESS', string>;

interface Props {
  children: ReactNode;
  urls: OnboardingUrls;
  forceKyc: boolean;
}

export const OnboardingProvider = ({
  children,
  urls,
  forceKyc = false,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { current: deepLink, redirectToDeeplink } = useDeepLink();
  const kycData = useKycData();
  const [, updateKycData] = useUpdateKycData(forceKyc);

  const [onboardingData, setOnboardingData] = useSessionStorage<OnboardingData>(
    ONBOARDING_STORAGE_KEY
  );

  const updateOnboardingDataFromKycData = useCallback(() => {
    if (!kycData) return;
    const { EMAIL, PHONE, ADDRESS, ONBOARDING, SOURCE_OF_FUNDS, PASSWORD } =
      kycData.actions.reduce(
        (allActions: Partial<Record<ActionKey, boolean>>, currAction) => {
          return {
            ...allActions,
            [currAction.name]: currAction.status !== 'APPROVED',
          };
        },
        {} as Partial<Record<ActionKey, boolean>>
      );

    setOnboardingData({
      emailActionRequired: !!EMAIL,
      requirePasswordInput: !!PASSWORD,
      phoneActionRequired: !!PHONE,
      addressActionRequired: !!ADDRESS,
      kycActionRequired: !!ONBOARDING,
      sourceOfFundsRequired: !!SOURCE_OF_FUNDS,
    });
  }, [kycData, setOnboardingData]);

  useEffect(() => {
    updateOnboardingDataFromKycData();
  }, [kycData, updateOnboardingDataFromKycData]);

  const updateOnboardingData = useCallback(
    (data: Partial<OnboardingData>) => {
      setOnboardingData({ ...onboardingData, ...data });
      updateKycData();
    },
    [onboardingData, setOnboardingData, updateKycData]
  );

  const goToNextStep = useCallback(() => {
    //Initially onboardingData is undefined, that's why added a check here
    if (!onboardingData || Object.keys(onboardingData).length === 0)
      return navigate(urls.STEPS);

    if (onboardingData.emailActionRequired) return navigate(urls.EMAIL);

    if (onboardingData.requirePasswordInput) return navigate(urls.PASSWORD);

    if (onboardingData.phoneActionRequired) return navigate(urls.PHONE);

    if (onboardingData.addressActionRequired) return navigate(urls.ADDRESS);

    if (onboardingData.kycActionRequired) {
      if (location.pathname === urls.STEPS) return navigate(urls.ONBOARDING);

      return navigate(urls.STEPS);
    }

    if (onboardingData.sourceOfFundsRequired) {
      return navigate(urls.SOURCE_OF_FUNDS);
    }

    updateKycData();

    if (deepLink && location.pathname !== deepLink) {
      return redirectToDeeplink();
    }

    return navigate(urls.SUCCESS, { state: { prevURL: location.pathname } });
  }, [
    onboardingData,
    deepLink,
    redirectToDeeplink,
    location,
    navigate,
    updateKycData,
    urls,
  ]);

  const value = useMemo(
    () => ({
      ...onboardingData,
      setOnboardingData: updateOnboardingData,
      goToNextStep,
      urls,
    }),
    [onboardingData, goToNextStep, urls, updateOnboardingData]
  );

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};
