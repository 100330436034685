/** @jsxImportSource @emotion/react */
import { Typography, useTheme } from '@quickbit/qb-design-system';
import QRCode from 'qrcode.react';
import { FormattedMessage } from 'react-intl';
import { Image } from 'components';

export const AppBanner = () => {
  const { breakpoints, sizing, palette, spacing } = useTheme();

  return (
    <div
      css={{
        display: 'none',
        [breakpoints.laptopSmall]: {
          display: 'flex',
          flexDirection: 'column',
          padding: spacing.l,
          background: palette.white,
          width: '100%',
          border: `1px solid ${palette.grey.x100}`,
          borderRadius: spacing.xxxs,
          maxWidth: sizing.large,
          height: sizing.large,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
        },
        [breakpoints.desktopSmall]: {
          margin: '0 auto',
        },
      }}
    >
      <div>
        <Image name="appBannerBg" size={`${sizing.medium}`} />
        <div
          css={{
            background: palette.primary.x500,
            padding: `calc(${spacing.xs}/2) calc(${spacing.xs}/2) calc(${spacing.xs}/4) calc(${spacing.xs}/2)`,
            borderRadius: spacing.xxxs,
            position: 'absolute',
            bottom: `calc(${spacing.xxl} - ${spacing.xxs})`,
            left: spacing.l,
          }}
        >
          <QRCode
            value={`${process.env.REACT_APP_HOME_URL}/app-download`}
            renderAs="canvas"
            bgColor={`${palette.primary.x500}`}
            fgColor={`${palette.white}`}
          />
        </div>
      </div>
      <div
        css={{
          textAlign: 'center',
          display: 'grid',
          gap: spacing.xxxs,
          marginTop: spacing.xxs,
        }}
      >
        <Typography variant="subtitle" color={palette.grey.x900}>
          <FormattedMessage id={'appBannerTitle'} />
        </Typography>
        <Typography color={palette.grey.x500}>
          <FormattedMessage id={'appBannerSubTitle'} />
        </Typography>
      </div>
    </div>
  );
};
