import { useTheme } from '@quickbit/qb-design-system';

export const useDefaultCSS = () => {
  const { palette, spacing, shape } = useTheme();

  return `
  :root {
      --buttons-color: ${palette.grey.x900} !important;
      --button-hover-color: ${palette.grey.x900} !important;
  }
  
  /*
      Hide stuff.
  */
  
  /* Creditcard logos at the top */
  .payment-method > .logo-container,
  /* "New card" action below credit card field, use the one in the credit card
      dropdown instead. (This will also hide the "Delete account" option if that's
      enabled) */
  
  .account-options-container {
      display: none !important;
  }
  
  /*
      Layout.
  */
  
  .payment-method {
      /* Not sure why this has margin-bottom by default. It causes the page
         content to scroll when it's 100% height. */
      margin-bottom: 0 !important;
      position: relative !important;
  }
  
  .payment-method-details {
      transition: none !important;
      display: flex !important;
      flex-direction: column !important;
      padding: ${spacing.s} ${spacing.xxs} ${spacing.m} !important;
      position: unset !important;
  }
  
  .submit-button-container {
      display: flex !important;
      flex-direction: column !important;
      flex: 1 0 auto !important;
      justify-content: space-between !important;
      align-items: center !important;
  }
  
  /*
      Transaction overview (Amount, Fee, Total).
  */
  
  .transaction-overview {
      width: 100% !important;
      padding: 0 ${spacing.xxs} !important;
      border: 0 !important;
  }
  
  .transaction-overview-row {
      padding: 0 !important;
  }
  
  .transaction-overview-row + .transaction-overview-row {
      margin-top: calc(${spacing.xxxs}/2) !important;
  }
  
  .transaction-overview .label {
      margin-right: 4px !important;
  }
  
  .transaction-overview-row.total-container .label {
      color: ${palette.black}!important;
      font-weight: 600 !important;
      font-size: 17px !important;
      line-height: 1.176470588 !important; /* Match .value line-height */
      /* zero: Slashed zero (not currently supported by Inter from Google Fonts, set it anyway for possible future support) */
      font-feature-settings: 'zero' on !important;
  }
  
  .transaction-overview-row.total-container .value {
      font-size: 20px !important;
      line-height: 1 !important;
  }
  
  /*
      Submit button inside transaction overview.
  */
  
  .submit-button {
      padding: ${spacing.xxs} ${spacing.s} !important;
      font-size: 16px !important;
      line-height: 20px !important;
      border-radius: ${shape.borderRadius} !important;
      font-weight: 500 !important;
  }
  
  .submit-button.loading {
      padding-right: calc(${spacing.s}*2) !important;
  }
  
  .submit-button.loading::after {
      /* Align in the middle of the 64px padding-right of the button */
      right: ${spacing.xs} !important;
      margin-right: 0 !important;
  }
  
  /*
      Creditcard selector dropdown.
  */
  
  .dropdown-container {
      position: unset !important;
      height: unset !important;
      padding: unset !important;
      background: unset !important;
      border: unset !important;
  }
  
  .dropdown-toggler {
      padding: ${spacing.xxs} !important;
      background: ${palette.white} !important;
      border: 1px solid ${palette.grey.x100}!important;
      border-radius: ${spacing.xxs} !important;
  }
  
  .dropdown-icon {
      display: none !important;
  }
  
  
  .dropdown-content {
      display: none !important;
      top: ${spacing.s} !important;
      bottom: 0 !important;
      max-height: unset !important;
      padding: ${spacing.xxs} !important;
      border-radius: ${spacing.xs} ${spacing.xs} 0 0 !important;
      box-shadow: 0px calc(${spacing.xxxs}/2) ${spacing.xs} rgba(0, 0, 0, 0.25) !important;
      transform: unset !important;
  }
  
  .dropdown-content::before {
      content: "Choose card" !important;
      display: block !important;
      margin: ${spacing.xxs} -${spacing.xxs} 28px !important;
      padding: 12px !important;
      border-bottom: 1px solid ${palette.grey.x100} !important;
      text-align: center !important;
      font-size: 18px !important;
      font-weight: 700 !important;
  }
  
  .dropdown.open .dropdown-content {
      display: block !important;
  }
  
  .dropdown-item {
      padding: 20px ${spacing.xxs} !important;
      background: ${palette.white} !important;
      border: 1px solid ${palette.grey.x100} !important;
  }
  
  .dropdown-item:first-child {
      border-radius: ${spacing.xxs} ${spacing.xxs} 0 0 !important;
  }
  
  .dropdown-item:last-child {
      border-radius: 0 0 ${spacing.xxs} ${spacing.xxs} !important;
  }
  
  .dropdown-item + .dropdown-item {
      border-top: 0 !important;
  }
  
  /* Use the selected item to create the "X" button that closes the dropdown, so
      that when it is pressed the item that is already selected will be selected
      again and there's no change to the users choice. */
  .dropdown-item.selected::before {
      content: "" !important;
      position: absolute !important;
      top: 28px !important;
      left: 0 !important;
      padding: ${spacing.xxs} !important;
      width: ${spacing.xs};
      height: ${spacing.xs};
      font-size: 18px !important;
      font-weight: 700 !important;
      /* base64 version of "close-icon.svg" */
      background: center no-repeat url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTEuMDMyMiAxMy43MjFMMTMuNzYzNy45ODkzbDEuMjY4NSAxLjI2ODVMMi4zMDA3IDE0Ljk4OTR6Ii8+PHBhdGggZmlsbD0iIzAwMCIgZD0iTTEzLjc2MzggMTQuOTg5NEwxLjAzMjMgMi4yNTc5IDIuMzAwOC45ODk0bDEyLjczMTUgMTIuNzMxNXoiLz48L3N2Zz4=");
  }
  
  .dropdown-item .flex {
      flex-direction: row-reverse !important;
      justify-content: flex-end !important;
  }
  
  .dropdown-item .heading-font-size {
      margin-top: unset !important;
      margin-left: ${spacing.xxs} !important;
      align-self: center !important;
      font-size: 17px !important;
      line-height: 0.941176471 !important;
  }
  
  .dropdown-item .logo-container {
      width: ${spacing.s} !important;
      text-align: center !important;
  }
  
  /* New account option */
  .dropdown-item:last-child .logo-container img {
      width: ${spacing.xs} !important;
      opacity: 0.5 !important;
  }
  
  /*
      Save account checkbox.
  */
  
  .checkbox-container {
      align-items: center !important;
      padding: 20px ${spacing.xxs} !important;
      background: ${palette.white} !important;
      border: 1px solid ${palette.grey.x100} !important;
      border-radius: ${spacing.xxs} !important;
  }
  
  .checkbox-container .input-title {
      margin-left: 11px !important;
      font-size: 17px !important;
  }
  
  .checkbox-container .checkbox-label .checkbox-custom {
      transition: none !important;
      width: ${spacing.xxs} !important;
      height: ${spacing.xxs} !important;
      border-radius: 50% !important;
      border: 2px solid ${palette.grey.x100} !important;
  }
  
  .checkbox-container .checkbox-label input:checked ~ .checkbox-custom {
      background: ${palette.primary.default} !important;
      border-color: ${palette.primary.default} !important;
  }
  
  .checkbox-container .checkbox-label .checkbox-custom::after {
      left: calc(${spacing.xxxs}/2) !important;
  }`;
};
