import {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { useAsyncFn, useMount } from 'react-use';
import { fetchKYCData } from 'api';
import { useCookies } from 'hooks';
import { KYCData } from 'types';
import { KYC_COOKIE_NAME } from '../constants';

type KycProviderValue =
  | (KYCData & {
      setKycData: (kycData: KYCData) => void;
    })
  | {
      setKycData: (kycData: KYCData) => void;
    };

const KycContext = createContext<KycProviderValue>({
  setKycData: () => undefined,
});

export const useSetKycData = () => {
  const { setKycData } = useContext(KycContext);
  return setKycData;
};

export const useUpdateKycData = (forceKyc?: boolean) => {
  const setKycData = useSetKycData();
  return useAsyncFn(async () => {
    const data = await fetchKYCData(forceKyc);
    setKycData(data);
    return data;
  }, []);
};

export const useKycData: () => KYCData | null = () => {
  const context = useContext(KycContext);

  const kycData = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { setKycData, ...rest } = context;
    return rest;
  }, [context]);

  return Object.keys(kycData).length > 0 ? (kycData as KYCData) : null;
};

export const KycProvider = ({ children }: { children: JSX.Element }) => {
  const [kycCookie, setKycCookie] = useCookies(KYC_COOKIE_NAME);
  const [kycData, setKycData] = useState<KYCData>();

  useMount(() => {
    if (!kycData) {
      if (kycCookie) setKycData(kycCookie as KYCData);
    }
  });

  const setKycDataAndCookie = useCallback(
    (kycData: KYCData) => {
      setKycCookie({ ...kycData });
      setKycData(kycData);
    },
    [setKycCookie, setKycData]
  );

  const context = useMemo(() => {
    return { ...kycData, setKycData: setKycDataAndCookie };
  }, [kycData, setKycDataAndCookie]);

  return <KycContext.Provider value={context}>{children}</KycContext.Provider>;
};
