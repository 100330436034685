/** @jsxImportSource @emotion/react */

import { createStyles } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { Layout, OnboardingStep } from 'components';
import { useKycData } from 'context/Kyc';
import {
  useGoToNextStep,
  useOnboardingData,
  useSetOnboardingData,
} from 'context/Onboarding';
import { useLoginCountry } from 'hooks';
import { ActionKey } from 'types';
import { isOnfidoCountry } from 'utils';

export const Steps = () => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const {
    addressActionRequired,
    emailActionRequired,
    phoneActionRequired,
    kycActionRequired,
    requirePasswordInput,
  } = useOnboardingData();
  const refreshOnbardingData = useSetOnboardingData();
  const goToNextStep = useGoToNextStep();
  const kycData = useKycData();
  const { loginCountry } = useLoginCountry();

  useMount(() => {
    refreshOnbardingData({});
  });

  const contactInfoNotRequired =
    !emailActionRequired && !phoneActionRequired && !addressActionRequired;

  const steps = [
    {
      titleId: 'steps.identity.title',
      subtitleId: isOnfidoCountry(loginCountry)
        ? 'steps.identity.onfido.subTitle'
        : 'steps.identity.bankID.subTitle',
      disabled: false,
      checked: true,
    },
  ];

  const contactInfoActions: ActionKey[] = ['EMAIL', 'ADDRESS', 'PHONE'];
  const contactInfoActionExists = contactInfoActions.some((v) =>
    (kycData?.actions?.map((action) => action.name) ?? []).includes(v)
  );
  const showContactInfo =
    kycData?.email?.verified ||
    kycData?.phone?.verified ||
    kycData?.address ||
    contactInfoActionExists;
  if (showContactInfo)
    steps.push({
      titleId: 'steps.contact.title',
      subtitleId: 'steps.contact.subTitle',
      disabled: false,
      checked: contactInfoNotRequired && !requirePasswordInput,
    });

  const onboardingActionExists = (['ONBOARDING'] as ActionKey[]).some((v) =>
    kycData?.actions.map((action) => action.name).includes(v)
  );
  if (onboardingActionExists) {
    steps.push({
      titleId: 'steps.kyc.title',
      subtitleId: 'steps.kyc.subTitle',
      disabled: showContactInfo && !contactInfoNotRequired,
      checked: !kycActionRequired,
    });
  }

  return (
    <Layout
      primaryButtonProps={{
        text: formatMessage({ id: 'continue' }),
        onClick: goToNextStep,
      }}
    >
      <div css={styles.main}>
        <div css={styles.content}>
          <div css={styles.steps}>
            {steps.map((step, idx) => (
              <OnboardingStep
                key={idx}
                title={formatMessage({ id: step.titleId })}
                subtitle={formatMessage({
                  id: step.subtitleId,
                })}
                stepNumber={idx + 1}
                disabled={step.disabled}
                checked={step.checked}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const useStyles = createStyles(
  ({ shape, palette, spacing, breakpoints, sizing }) => ({
    main: {
      borderRadius: shape.borderRadius,
      background: palette.white,
      marginTop: spacing.s,
      [breakpoints.laptopSmall]: {
        minWidth: sizing.medium,
        marginTop: 0,
      },
    },
    content: {
      padding: `${spacing.xs} ${spacing.xxs} ${spacing.xs} ${spacing.xxs}`,
    },
    steps: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing.xs,
      width: '100%',
    },
  })
);
