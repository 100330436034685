import { Locale } from 'types';
import { copy } from '../translations';

const messages = Object.entries(copy).reduce(
  (acc, value) => ({
    'en-US': { ...acc['en-US'], [value[0]]: value[1]['en-US'] },
    sv: {
      ...acc['sv'],
      [value[0]]: (value as [string, Record<string, string>])[1]['sv'],
    },
    nn: { ...acc['en-US'], [value[0]]: value[1]['en-US'] },
  }),
  { 'en-US': {}, sv: {}, nn: {} }
);

export const getMessages = (locale: Locale) => messages[locale];
1;
