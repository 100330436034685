import { fetchAPI } from 'api';
import { type UserConsents, type ConsentType } from 'types';

export const fetchUserConsents = async () => {
  const { consents } = await fetchAPI<{
    consents: ConsentType[];
    userConsents: UserConsents;
  }>('kyc/consents');
  const userConsents: UserConsents = consents.reduce(
    (acc, curr) => ({
      ...acc,
      ...(!curr.isObligatory ? { [curr.id]: curr.isConsenting } : {}),
    }),
    {}
  );
  return { consents, userConsents };
};

export const saveUserConsents = (consentsToUpdate: UserConsents) => {
  return fetchAPI('kyc/consents', {
    method: 'PATCH',
    body: { consentsToUpdate },
  });
};
