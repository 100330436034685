import { Currency } from '@quickbit/qb-design-system';

export enum OrderStatus {
  INITIATED = 'INITIATED',
  ASSIGNED = 'ASSIGNED',
  SEND_PREVIEW_CREATED = 'SEND_PREVIEW_CREATED',
  BUY_AND_SEND_PREVIEW_CREATED = 'BUY_AND_SEND_PREVIEW_CREATED',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export interface Order {
  amount: number;
  currency: Currency;
  merchantId: string;
  merchantName: string;
  uuid: string;
  createdAt: Date;
  status: OrderStatus;
}
