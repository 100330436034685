import { useIntl } from 'react-intl';
import { MoneyScaled } from 'types';
import { SUPPORTED_CRYPTO_CURRENCIES } from '../constants';
import { removeScaleFromMoney } from '../utils/money';

export const useFormatCurrency = (): ((money: MoneyScaled) => string) => {
  const { formatNumber } = useIntl();

  return (money: MoneyScaled): string => {
    const amount = removeScaleFromMoney(money);

    if (SUPPORTED_CRYPTO_CURRENCIES.includes(money.currencyCode)) {
      return `${formatNumber(amount, {
        minimumSignificantDigits: 3,
      })} ${money.currencyCode}`;
    }

    return formatNumber(amount, {
      style: 'currency',
      currency: money.currencyCode,
      currencyDisplay: 'narrowSymbol',
      minimumSignificantDigits: 3,
    });
  };
};
