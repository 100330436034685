/** @jsxImportSource @emotion/react */
import {
  Icon,
  IconName,
  Typography,
  createStyles,
  useTheme,
} from '@quickbit/qb-design-system';
import { FormattedMessage } from 'react-intl';

interface Props {
  actions: {
    textId: string;
    icon: IconName;
    onClick: () => void;
  }[];
}

export const ActionsTab = ({ actions }: Props) => {
  const { spacing } = useTheme();
  const styles = useStyles();

  return (
    <div css={styles.main}>
      {actions.map(({ textId, icon, onClick }) => (
        <div
          key={textId}
          onClick={onClick}
          data-testid={`${textId}-tab`}
          css={styles.action}
        >
          <Icon name={icon} size={spacing.xs} />
          <Typography variant="captionMedium">
            <FormattedMessage id={textId} />
          </Typography>
        </div>
      ))}
    </div>
  );
};

const useStyles = createStyles(({ spacing, shape, palette }) => ({
  main: {
    height: spacing.l,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: shape.borderRadius,
    backgroundColor: palette.white,
    cursor: 'pointer',
    svg: {
      color: palette.black,
    },
  },
  action: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: {
      marginTop: spacing.xxxs,
    },
  },
}));
