/** @jsxImportSource @emotion/react */
import { useTheme } from '@quickbit/qb-design-system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorResponse } from 'api/fetchAPI';
import { PaymentIQIFrame, ErrorComponent } from 'components';
import { useMixpanel } from 'context';
import { useSetTopUpData, useTopUpData } from 'top-up-pages/context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertPiqErrorToErrorResponse = (errorData: any): ErrorResponse => {
  const errorCode =
    'data' in errorData && 'statusCode' in errorData.data
      ? errorData.data.statusCode
      : undefined;
  return {
    errorCode: errorCode ?? 'UNKNOWN_PIQ_ERROR',
    reason: errorCode
      ? `Purchase failed with error code: ${errorCode}`
      : 'Purchase failed due to an unknown error.',
  };
};

export const CashierBuy = () => {
  const navigate = useNavigate();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Buy',
  });
  const { buyPreviewId, merchantName, merchantId } = useTopUpData();
  const setTopUpData = useSetTopUpData();
  const [piqError, setPiqError] = useState<ErrorResponse>();
  const { breakpoints } = useTheme();

  if (!buyPreviewId) {
    throw Error('Invalid state');
  }

  if (piqError) {
    return (
      <ErrorComponent
        error={piqError}
        extraInfo={'Please try again.'}
        tryAgainAction={() => navigate('/top-up')}
      />
    );
  }

  return (
    <div
      css={{
        height: '100%',
        width: '100%',
        [breakpoints.laptopSmall]: { height: '85%', marginTop: '7.5dvh' },
      }}
    >
      <PaymentIQIFrame
        previewId={buyPreviewId}
        qbMerchantId={merchantId}
        product={'topup'}
        onSuccess={() => {
          mixpanelTrackEvent('Buy completed', {
            description: 'User completed the buy process.',
            merchantName,
            frontBuy: false,
            buyPreviewId: buyPreviewId,
          });
          setTopUpData({ shouldSendAutomatically: true });
          navigate(`/top-up`);
        }}
        onError={(errorData) => {
          console.error(errorData);
          setPiqError(convertPiqErrorToErrorResponse(errorData));
        }}
        onCancelled={(errorData) => {
          console.error(errorData);
          setPiqError(convertPiqErrorToErrorResponse(errorData));
        }}
        onCashierDataFailed={() => navigate(`/top-up`)}
      />
    </div>
  );
};
