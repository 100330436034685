/** @jsxImportSource @emotion/react */
import { createStyles, Typography, useTheme } from '@quickbit/qb-design-system';
import { Checkmark } from './Checkmark';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  title: string;
  subtitle: string;
  stepNumber: number;
}

export const OnboardingStep = ({
  checked,
  disabled,
  title,
  subtitle,
  stepNumber,
}: Props) => {
  const styles = useStyles();
  const { palette } = useTheme();

  return (
    <div css={[styles.main, { ...(disabled ? styles.disabled : {}) }]}>
      <Checkmark checked={checked} number={stepNumber} />
      <div>
        <Typography
          variant="bodyBold"
          color={checked ? palette.grey.x300 : palette.black}
        >
          {title}
        </Typography>
        <Typography
          variant="bodySmall"
          color={checked ? palette.grey.x300 : palette.grey.default}
        >
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = createStyles(({ spacing }) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  disabled: {
    opacity: '0.5',
  },
}));
