import { PasswordStrengthVariant } from '@quickbit/qb-design-system';
import { zxcvbnOptions, zxcvbn } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';

export const getPasswordStrength = (password: string) => {
  const suggestionsList: string[] = [];
  const variants: Record<number, PasswordStrengthVariant> = {
    1: 'weak',
    2: 'okay',
    3: 'good',
    4: 'veryGood',
  };
  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  };

  zxcvbnOptions.setOptions(options);

  const zxcvbnResults = zxcvbn(password);
  const feedback = zxcvbnResults.feedback;

  if (password.length < 8) {
    suggestionsList.push('The password is too short.');
  }
  if (password.length > 128) {
    suggestionsList.push('The password is too long.');
  }
  if (zxcvbnResults.score < 2) {
    suggestionsList.push('The password is too weak.');
  }
  if (feedback.warning) {
    suggestionsList.push(feedback.warning);
  }
  feedback.suggestions.forEach((suggestion) => {
    suggestionsList.push(suggestion);
  });

  return {
    isPasswordStrong:
      password.length >= 8 &&
      password.length <= 128 &&
      zxcvbnResults.score >= 2,
    variant: variants[zxcvbnResults.score] || 'weak',
    suggestions: suggestionsList,
  };
};
