/** @jsxImportSource @emotion/react */
import {
  createStyles,
  Switch,
  Typography,
  useTheme,
} from '@quickbit/qb-design-system';
import { Dispatch } from 'react';
import { useIntl } from 'react-intl';
import {
  type ConsentType,
  type UserConsents,
  type UserConsentReducer,
} from 'types';

interface Props {
  consents: ConsentType[];
  setUserConsents: Dispatch<UserConsentReducer>;
  userConsents: UserConsents;
}

export const Consents = ({
  consents,
  setUserConsents,
  userConsents,
}: Props) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();
  const { palette } = useTheme();

  const handleOnChange = (checked: boolean, name: string) => {
    setUserConsents({ type: 'UPDATE', payload: { [name]: checked } });
  };

  return (
    <div css={styles.main}>
      <Typography variant="bodyBold">
        {formatMessage({ id: 'page.consent.editConsent.heading' })}
      </Typography>
      <Typography variant="body">
        {formatMessage({ id: 'page.consent.editConsent.subHeading' })}
      </Typography>
      <div css={styles.consents}>
        {consents.map(({ id, name, description, isObligatory }) => (
          <div key={id}>
            <div css={styles.consentTitle}>
              <Typography>{name}</Typography>
              <Switch
                checked={isObligatory || userConsents[id]}
                disabled={isObligatory}
                onChange={(checked) => handleOnChange(checked, id)}
              />
            </div>
            <Typography variant="bodySmall" color={palette.grey.default}>
              {description}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = createStyles(({ spacing }) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxs,
    margin: `${spacing.xs} 0`,
  },
  consents: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxs,
    marginTop: spacing.s,
  },
  consentTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: spacing.xxxs,
  },
}));
