/** @jsxImportSource @emotion/react */
import { createStyles } from '@quickbit/qb-design-system';
import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children?: ReactNode;
  onClose?: () => void;
}

export const LayoutModal = ({ children, onClose }: Props) => {
  const styles = useStyles();
  const [modalRoot, setModalRoot] = useState<HTMLElement>();
  useEffect(() => {
    const root = document.getElementById('modal-root');
    if (!root) {
      throw Error(
        'div [id=modal-root] not found. Have you forgotten DesignSystemProvider'
      );
    }
    setModalRoot(root);
  }, []);

  if (!modalRoot) {
    return null;
  }

  return createPortal(
    <>
      <div css={[styles.spanAll, styles.backdrop]} />
      <div css={[styles.spanAll]} onClick={() => onClose && onClose()}>
        <div css={styles.modal} id="MODAL" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </>,
    modalRoot
  );
};

const useStyles = createStyles(({ palette, sizing, breakpoints, shape }) => ({
  spanAll: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    [breakpoints.laptopSmall]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  backdrop: {
    background: `${palette.grey.x600}69`,
    backdropFilter: 'blur(3px)',
  },
  modal: {
    background: palette.grey.x50,
    height: '100%',
    [breakpoints.laptopSmall]: {
      height: 'unset',
      minWidth: sizing.medium,
      maxWidth: sizing.medium,
      borderRadius: shape.borderRadius,
      overflow: 'hidden',
    },
  },
}));
