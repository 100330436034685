import { useState, createContext, useContext, useMemo } from 'react';
import { useLocalStorage, useMount } from 'react-use';
import { useLocale, useLoginCountry } from 'hooks';
import { getExpirationDate } from 'hooks/useCookies';
import { type Locale } from 'types';

export type SessionData = {
  firstOnboardingCompleted: boolean;
  email?: string;
  fullName?: string;
  expires?: number;
} | null;

type SessionProviderValue =
  | (SessionData & {
      setSession: (sessionData: SessionData) => void;
    })
  | {
      setSession: (sessionData: SessionData) => void;
    };

export const SESSION_STORE_NAME = 'qb_web_session';

const countryLocale: Record<string, Locale> = {
  SE: 'sv',
  NO: 'nn',
};

const SessionContext = createContext<SessionProviderValue>({
  setSession: () => undefined,
});

export const useSetSession = () => {
  const [sessionStore, setSessionStore] =
    useLocalStorage<SessionData>(SESSION_STORE_NAME);
  const { setSession } = useContext(SessionContext);
  const { changeLocale } = useLocale();
  const { loginCountry } = useLoginCountry();
  return (sessionData: SessionData, tokenTtlInSeconds?: number) => {
    if (loginCountry in countryLocale) {
      changeLocale(countryLocale[loginCountry]);
    } else {
      changeLocale('en-US');
    }

    const expires = tokenTtlInSeconds
      ? getExpirationDate(tokenTtlInSeconds).getTime()
      : sessionStore?.expires ?? getExpirationDate(30 * 24 * 60 * 60).getTime();

    setSessionStore(sessionData ? { ...sessionData, expires } : null);
    setSession(sessionData);
  };
};

export const useClearSession = () => {
  const { setSession } = useContext(SessionContext);
  const [, , removeSessionStore] =
    useLocalStorage<SessionData>(SESSION_STORE_NAME);

  return () => {
    removeSessionStore();
    sessionStorage.clear();
    setSession(null);
  };
};

export const useSession: () => SessionData = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within SessionProvider');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setSession, ...rest } = context;

  return Object.keys(rest).length > 0 ? (rest as SessionData) : null;
};

export const SessionProvider = ({ children }: { children: JSX.Element }) => {
  const [sessionStore, , removeSessionStore] =
    useLocalStorage<SessionData>(SESSION_STORE_NAME);
  const [session, setSession] = useState<SessionData>();

  useMount(() => {
    if (!session && sessionStore) {
      const expiresDate =
        sessionStore && sessionStore.expires
          ? new Date(sessionStore?.expires)
          : getExpirationDate(30 * 24 * 60 * 60);
      if (expiresDate > new Date()) {
        setSession(sessionStore as SessionData);
      } else {
        removeSessionStore();
      }
    }
  });

  const value = useMemo(() => ({ ...session, setSession }), [session]);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};
