import { Locale } from 'types';
import { contentfulGraphql, Query } from './contentful';

interface MicroArticle {
  key: string;
  title: string;
  body: string;
}

export interface MicroArticleByKey {
  [key: string]: string;
}

const mapMicroArticleByKey = (items: MicroArticle[]): MicroArticleByKey => {
  const mapping: MicroArticleByKey = {};
  for (const item of items) {
    const titleKey = `${item.key}.title`;
    const bodyKey = `${item.key}.body`;

    mapping[titleKey] = item.title;
    mapping[bodyKey] = item.body;
  }

  return mapping;
};

const microArticleQuery = `
query (
  $locale: String!
) {
  collection: microArticleCollection(locale: $locale) {
		items {
			key
			title
			body
		}
	}
}
`;

export const getMicroArticles = async (locale: Locale) => {
  const query: Query = {
    query: microArticleQuery,
    variables: {
      locale,
    },
  };

  const gqlResult = await contentfulGraphql<{
    collection: {
      items: MicroArticle[];
    };
  }>(query);

  const articles = mapMicroArticleByKey(gqlResult.collection.items);
  return articles;
};
