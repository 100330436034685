import { Currency } from '@quickbit/qb-design-system';

const BECH32_REGEX = new RegExp('^bc1[A-AC-HJ-NP-Za-ac-hj-np-z2-90]{11,71}$');
const P2SH_REGEX = new RegExp('^[13][a-km-zA-HJ-NP-Z1-9]{25,33}$');
// NOTE: Nexus does not support LTC addresses with the "3" prefix
const LITECOIN_REGEX = new RegExp('^[LM][a-km-zA-HJ-NP-Z1-9]{25,33}$');
const LITECOIN_BECH32_REGEX = new RegExp(
  '^ltc1[A-AC-HJ-NP-Za-ac-hj-np-z2-90]{11,71}$'
);
const CASHADDR_REGEX = new RegExp('^(bitcoincash:)?[qp][a-z0-9]{41,120}$');
const ETHER_REGEX = new RegExp('^0x[a-fA-F0-9]{40}$');

const ADDRESS_FORMATS: Record<string, RegExp[]> = {
  BTC: [BECH32_REGEX, P2SH_REGEX],
  LTC: [LITECOIN_REGEX, LITECOIN_BECH32_REGEX],
  BCH: [CASHADDR_REGEX],
  ETH: [ETHER_REGEX],
};

export const isValidCryptoAddress = (
  address: string,
  currency: Currency
): boolean => {
  if (!Object.keys(ADDRESS_FORMATS).includes(currency)) {
    console.error('Unsupported crypto currency');
    return false;
  }
  const regExps = ADDRESS_FORMATS[currency];
  return regExps.some((regex) => regex.test(address));
};
