/** @jsxImportSource @emotion/react */
import { Typography, createStyles, useTheme } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { useMount } from 'react-use';
import { type ErrorResponse } from 'api/fetchAPI';
import { Layout, Image } from 'components';
import { useMixpanel } from 'context';

interface Props {
  error?: ErrorResponse;
  tryAgainAction?: () => void;
  extraInfo?: string;
  title?: string;
}

export const ErrorComponent = ({
  error,
  tryAgainAction,
  extraInfo,
  title,
}: Props) => {
  const styles = useStyle();
  const { spacing, palette } = useTheme();
  const { formatMessage } = useIntl();
  const { mixpanelTrackEvent } = useMixpanel({
    Page: 'ErrorComponent',
  });

  useMount(() => {
    mixpanelTrackEvent('ErrorComponent mounted', { error });
  });

  return (
    <Layout
      primaryButtonProps={
        tryAgainAction
          ? {
              text: formatMessage({
                id: 'page.error.button.tryAgain',
              }),
              onClick: tryAgainAction,
            }
          : undefined
      }
    >
      <div css={styles.container}>
        <div css={styles.child}>
          <span css={styles.emptyStateImage}>
            <Image name="emptyState" size={spacing.xl} />
          </span>
          <div css={styles.errorHeading}>
            <Typography variant="subtitle">
              {title ? title : formatMessage({ id: 'page.error.heading' })}
            </Typography>
          </div>
          <div css={styles.errorReason}>
            {error && (
              <>
                {error.errorCode == 'FORM_VALIDATION_FAILED' ? (
                  error.formErrors.map((formError) => (
                    <Typography variant="subtitle" key={formError.reason}>
                      {formError.reason}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body" color={palette.grey.x500}>
                    {error.reason}
                  </Typography>
                )}
                {error.tracingId && (
                  <div css={{ marginTop: spacing.xxs, display: 'flex' }}>
                    <Typography
                      variant="bodySmallestBold"
                      color={palette.grey.x500}
                    >
                      Support ID:&nbsp;
                    </Typography>
                    <Typography
                      variant="bodySmallest"
                      color={palette.grey.x500}
                    >
                      {error.tracingId}
                    </Typography>
                  </div>
                )}
              </>
            )}
            {extraInfo && (
              <Typography variant="body" color={palette.grey.x500}>
                {extraInfo}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const useStyle = createStyles(({ spacing, sizing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  child: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: sizing.medium,
  },
  emptyStateImage: {
    marginTop: `${spacing.xl}`,
  },
  errorReason: {
    marginTop: `${spacing.xxxs}`,
    textAlign: 'center',
  },
  orderId: {
    marginTop: `${spacing.xxxs}`,
    textAlign: 'center',
  },
  errorHeading: {
    marginTop: `${spacing.m}`,
  },
}));
