/** @jsxImportSource @emotion/react */
import { useTheme } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Status, ErrorComponent } from 'components';
import { RedirectWithReturnLink, useMixpanel } from 'context';
import { useFormatCurrency } from 'hooks';
import { TopUpLayout } from 'top-up-pages/components';
import { useTopUpData } from '../context';
import { sendOrder } from './api';

export const Processing = () => {
  const { formatMessage } = useIntl();
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const { cryptoAmount, previewId, merchantName } = useTopUpData();
  const formatCurrency = useFormatCurrency();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Processing',
  });

  if (!(cryptoAmount && previewId)) {
    throw Error('Invalid state');
  }

  const { value, loading, error } = useAsync(async () => {
    mixpanelTrackEvent('Processing started', {
      description: 'User started the send order processing step.',
      merchantName,
    });
    const sendOrderResponse = await sendOrder(previewId);

    if (sendOrderResponse) {
      mixpanelTrackEvent('Processing completed', {
        description: 'User completed the send order processing step.',
        merchantName,
      });
    }

    return sendOrderResponse;
  });

  if (error) {
    if (error.errorCode === 'COMPLETED_PREVIEW') {
      return <RedirectWithReturnLink to={`/top-up/completed`} />;
    }
    if (error.errorCode === 'INSUFFICIENT_FUNDS') {
      return <RedirectWithReturnLink to={`/top-up/buy`} />;
    }
    if (error.errorCode === 'DELETED_PREVIEW') {
      return <RedirectWithReturnLink to={`/top-up/cancelled`} />;
    }
    return <ErrorComponent error={error} tryAgainAction={() => navigate(-1)} />;
  }

  return (
    <TopUpLayout
      topChildren={
        <div>
          {(!value || loading) && (
            <Status
              heading={formatMessage({
                id: 'page.send.processing.heading',
              })}
              subHeading={formatMessage(
                {
                  id: 'page.send.processing.subheading',
                },
                { walletAmount: formatCurrency(cryptoAmount) }
              )}
              imageProps={{
                name: 'transferLoading',
                size: spacing.xxl,
                showPlaceholder: false,
              }}
            />
          )}
          {value && <Navigate to={'/top-up/success'} replace />}
        </div>
      }
    />
  );
};

export default Processing;
