import { Theme, Interpolation } from '@emotion/react';
import { useTheme } from '@quickbit/qb-design-system';
import { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
  markdown: string;
  css?: Interpolation<Theme>;
}

const components = (theme: ReturnType<typeof useTheme>) => {
  const { palette } = theme;
  return {
    a: (props: { href?: string; children: ReactNode }) => (
      <a
        {...props}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: palette.primary.default }}
      />
    ),
  };
};

export const Markdown = ({ markdown, css }: Props) => {
  const theme = useTheme();
  return (
    <div css={css}>
      <ReactMarkdown components={components(theme)}>{markdown}</ReactMarkdown>
    </div>
  );
};
