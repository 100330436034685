/** @jsxImportSource @emotion/react */
import {
  Typography,
  Icon,
  useTheme,
  createStyles,
} from '@quickbit/qb-design-system';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Footer = () => {
  const { palette, spacing } = useTheme();
  const styles = useStyles();
  return (
    <div css={styles.default}>
      <span css={styles.content}>
        <Icon name="qb" size={spacing.xxs} />
        <Typography variant="captionSmallMedium" color={palette.grey.x400}>
          <FormattedMessage id="footer.slogan" />
        </Typography>
      </span>
    </div>
  );
};

const useStyles = createStyles(({ spacing, palette }) => ({
  default: { display: 'flex', justifyContent: 'center' },
  content: {
    display: 'grid',
    gap: spacing.xxxs,
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    lineHeight: 0,
    svg: {
      color: palette.grey.x400,
    },
  },
}));
