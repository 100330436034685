import { Outlet } from 'react-router-dom';
import { RedirectWithReturnLink, useSession } from 'context';

interface Props {
  redirectUrl: string;
}

export const ProtectedRoute = ({ redirectUrl }: Props) => {
  const session = useSession();

  if (!session) {
    return <RedirectWithReturnLink to={redirectUrl} />;
  }

  return <Outlet />;
};
