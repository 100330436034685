import { Currency, FiatCurrency, Money } from '@quickbit/qb-design-system';
import { useCallback, useMemo } from 'react';
import { useAsync } from 'react-use';
import { fetchExchangeRates } from 'api';

const findRate = (rates: Money[], toCurrency: Currency) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return rates.find((val) => val.currencyCode == toCurrency)!;
};

interface BuySendOrSwap {
  type: 'BUY' | 'SEND' | 'SWAP';
  config: {
    fiat: Currency;
    crypto: Currency;
  };
}

interface Sell {
  type: 'SELL';
  config: {
    crypto: Currency;
  };
}

export type ConversionConfig = BuySendOrSwap | Sell;

export const useConvertAmount = ({ type, config }: ConversionConfig) => {
  const [baseCurrency, otherCurrency] = useMemo(() => {
    switch (type) {
      case 'BUY':
      case 'SEND': {
        return [config.fiat, config.crypto];
      }
      case 'SWAP': {
        return [config.crypto, config.fiat];
      }
      case 'SELL':
        return [config.crypto, 'EUR' as Currency];
    }
  }, [type, config]);

  const { value: exchangeRates } = useAsync(
    async () => fetchExchangeRates(type, baseCurrency),
    [type, baseCurrency]
  );

  const convert = useCallback(
    (amount: Money): Money => {
      if (!exchangeRates) return amount;
      const amountNumber = amount.amount;

      const toCurrency =
        amount.currencyCode === baseCurrency ? otherCurrency : baseCurrency;

      const rate = findRate(exchangeRates, otherCurrency);

      let newAmount = null;
      if (toCurrency !== baseCurrency) {
        newAmount = rate.amount * amountNumber;
      } else {
        newAmount = amountNumber / rate.amount;
      }
      return {
        amount: Number(newAmount.toFixed(isFiat(toCurrency) ? 2 : 8)),
        currencyCode: toCurrency,
      };
    },
    [exchangeRates, baseCurrency, otherCurrency]
  );

  return convert;
};

const isFiat = (currency: Currency) => {
  const fiats: Record<FiatCurrency, true> = {
    EUR: true,
    NOK: true,
    SEK: true,
    USD: true,
  };
  return currency in fiats;
};
