import { createContext, useContext, ReactNode } from 'react';
import { type Config } from 'types';

const Context = createContext<Config>({
  openWindow: () => undefined,
  closeWindow: () => undefined,
});

export const ConfigProvider = ({
  children,
  config,
}: {
  children: ReactNode;
  config: Config;
}) => {
  return <Context.Provider value={config}>{children}</Context.Provider>;
};

export const useConfig = (): Config => {
  return useContext(Context);
};
