export const FEATURE_FLAG = Object.freeze({
  NOT_IN_PRODUCTION: 'NOT_IN_PRODUCTION',
  ENABLE_EURO_ACCOUNT_CREATION: 'ENABLE_EURO_ACCOUNT_CREATION',
  EXPERIMENT_A: 'EXPERIMENT_A',
} as const);

type FeatureFlagKeys = keyof typeof FEATURE_FLAG;
export type FeatureFlagValues = (typeof FEATURE_FLAG)[FeatureFlagKeys];

export const ACTIVE_ENVIRONMENTS = Object.freeze({
  local: 'local',
  test: 'test',
  production: 'production',
} as const);

type EnvironmentKeys = keyof typeof ACTIVE_ENVIRONMENTS;
export type EnvironmentValues = (typeof ACTIVE_ENVIRONMENTS)[EnvironmentKeys];
