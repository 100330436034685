/** @jsxImportSource @emotion/react */
import {
  Icon,
  useTheme,
  Typography,
  ListItem,
  List,
  IconName,
  createStyles,
} from '@quickbit/qb-design-system';
import { useState } from 'react';
import { type Country } from 'types';

interface Props {
  setCountry: (value: Country) => void;
  country: Country | undefined;
  appCountries?: Country[];
}

export const CountrySelect = ({ setCountry, country, appCountries }: Props) => {
  const { spacing } = useTheme();
  const [showCountries, setShowCountries] = useState(false);
  const styles = useStyles();

  if (!appCountries) return null;

  return (
    <div css={styles.main}>
      <div
        css={styles.dropdown}
        onClick={() => setShowCountries(!showCountries)}
      >
        {country && (
          <>
            <Icon name={`flag-${country.code}` as IconName} />
            <Typography dataTestId="country-name">
              {country.name || 'Sweden'}
            </Typography>
            <div
              css={{
                svg: {
                  rotate: '-90deg',
                },
                marginLeft: `calc(-${spacing.xxxs}/2)`,
              }}
            >
              <Icon name="chevron" size={spacing.xxs} />
            </div>
          </>
        )}
      </div>
      {showCountries && (
        <div onClick={() => setShowCountries(!showCountries)} css={styles.list}>
          <List>
            {appCountries.map((country) => (
              <ListItem
                title={country.name}
                icon={`flag-${country.code}` as IconName}
                onClick={() => {
                  setCountry(country);
                }}
                key={country.code}
              />
            ))}
          </List>
        </div>
      )}
    </div>
  );
};

const useStyles = createStyles(({ spacing }) => ({
  main: {
    position: 'relative',
    marginTop: spacing.xxs,
    display: 'flex',
    gap: spacing.xxxs,
    flexDirection: 'column',
  },
  dropdown: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacing.xxxs,
    cursor: 'pointer',
  },
  list: {
    position: 'absolute',
    marginTop: spacing.xxs,
    maxHeight: spacing.xxxl,
    overflowY: 'scroll',
    zIndex: 20,
    top: '100%',
    display: 'flex',
    alignSelf: 'center',
    ul: {
      height: '100%',
      minWidth: 'fit-content',
    },
  },
}));
