/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import {
  createStyles,
  Typography,
  Icon,
  useTheme,
} from '@quickbit/qb-design-system';
import { ReactNode, useCallback, useState, TouchEvent } from 'react';

interface Props {
  title?: string;
  children?: ReactNode;
  onClose?: () => void;
}

export const PullUpModal = ({ title, children, onClose }: Props) => {
  const { spacing } = useTheme();
  const styles = useStyles();
  const [margin, setMargin] = useState<number>();

  const handleDrag = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (e.touches.length > 0) {
        setMargin(Math.max(e.touches[0].clientY, parseInt(spacing.m)));
      }
    },
    [setMargin, spacing.m]
  );

  const handleDragEnd = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (margin && margin > parseInt(spacing.xl)) {
        setMargin(undefined);
      }
    },
    [margin, spacing.xl]
  );

  return (
    <div
      css={styles.main}
      onClick={(e) => {
        e.stopPropagation();
        onClose && onClose();
      }}
    >
      <div css={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div
          css={[
            styles.header,
            !margin && { transition: 'margin-top 200ms ease-out' },
            margin && { marginTop: `${margin}px !important` },
          ]}
          onTouchMove={handleDrag}
          onTouchEnd={handleDragEnd}
        >
          <span css={styles.headerTitle}>
            {title && <Typography variant="bodyBold">{title}</Typography>}
            <span css={styles.closeIcon} onClick={onClose}>
              <Icon name="close" />
            </span>
          </span>
        </div>

        <div css={styles.content}>{children}</div>
      </div>
    </div>
  );
};

const useStyles = createStyles(
  ({ palette, spacing, breakpoints, sizing, shape }) => ({
    main: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      zIndex: 999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      background: `${palette.grey.x600}69`,
      backdropFilter: 'blur(3px)',
      height: '100%',
      maxWidth: '100% !important',
      [breakpoints.laptopSmall]: {
        alignItems: 'center',
        minHeight: '100%',
      },
    },
    modal: {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      overflowY: 'scroll',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.laptopSmall]: {
        display: 'block',
        height: 'unset',
        maxWidth: sizing.medium,
        borderRadius: spacing.xxxs,
        background: palette.white,
        position: 'unset',
      },
    },
    header: {
      marginTop: spacing.m,
      animation: `${keyframes`
        0% {
          margin-top: 90vh;
        }
        100% {
          margin-top: ${spacing.m};
        }
        `} 200ms ease-out`,
      borderBottom: `1px solid ${palette.grey.x100}`,
      borderTopLeftRadius: spacing.xs,
      borderTopRightRadius: spacing.xs,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing.xxs,
      background: palette.white,
      [breakpoints.laptopSmall]: {
        animation: 'none',
        marginTop: 0,
        borderRadius: `${spacing.xxxs}  ${spacing.xxxs} 0 0`,
      },
    },
    content: {
      flex: 1,
      padding: spacing.xxs,
      overflowX: 'hidden',
      overflowY: 'scroll',
      backgroundColor: palette.white,
      [breakpoints.laptopSmall]: {
        margin: '0',
        maxHeight: `calc(${sizing.large} - ${spacing.xl})`,
        borderBottomRightRadius: shape.borderRadius,
        borderBottomLeftRadius: shape.borderRadius,
        height: '100%',
      },
    },
    closeIcon: {
      cursor: 'pointer',
      svg: {
        stroke: palette.black,
        fill: 'none',
      },
    },
    headerTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: spacing.xxxs,
      width: '100%',
      p: {
        width: '100%',
        textAlign: 'center',
      },
    },
  })
);
