/** @jsxImportSource @emotion/react */
import { createStyles } from '@quickbit/qb-design-system';
import { Outlet } from 'react-router-dom';
import { TopUpProvider } from './context';

export { Preview as TopUpSendPreview } from './Preview';
export { Processing as TopUpSendProcessing } from './Processing';
export { Buy as TopUpBuy } from './Buy';
export { CashierBuy as TopUpCashierBuy } from './Buy/CashierBuy';
export {
  OnboardingLayout as TopUpOnboardingLayout,
  Steps as TopUpOnboardingSteps,
} from './Onboarding';
export { Login as TopUpLogin } from './Login';
export { EmailLogin as TopUpEmailLogin } from './EmailLogin';
export { Failure as TopUpFailure } from './Failure';
export { Success as TopUpSuccess } from './Success';
export { Cancelled as TopUpCancelled } from './Cancelled';
export { Completed as TopUpCompleted } from './Completed';

export const TopUpOutlet = () => {
  const styles = useStyles();

  return (
    <TopUpProvider>
      <div css={styles.container}>
        <div css={styles.content}>
          <Outlet />
        </div>
      </div>
    </TopUpProvider>
  );
};

const useStyles = createStyles(({ breakpoints, sizing }) => ({
  container: {
    height: '100dvh',
    width: '100%',
    [breakpoints.tabletSmall]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  content: {
    position: 'relative',
    width: '100%',
    height: '100%',
    [breakpoints.tabletSmall]: {
      maxWidth: sizing.medium,
    },
  },
}));
