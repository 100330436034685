import { useCallback, useMemo } from 'react';
import { KycFormAnswers } from 'types';
import {
  Branch,
  OnboardingSchema,
  Question,
} from '../pages/Onboarding/Kyc/api';

interface Condition {
  sourceCode: string;
  shouldInclude: string;
}
interface OnboardingRoute {
  code: string;
  conditions?: Condition;
  question: Question;
}

/**
 * Recursively traverse the branches parameter and return a
 * flattened array of OnboardingRoutes.
 */
const flattenRoutes = (
  branches: Branch[],
  conditions?: Condition
): OnboardingRoute[] =>
  branches.reduce(
    (prev: OnboardingRoute[], { question, choiceBranches }: Branch) => {
      let acc = [
        ...prev,
        {
          code: question.code,
          conditions,
          question,
        },
      ];
      if (!choiceBranches) return acc;
      Object.keys(choiceBranches).forEach((key) => {
        acc = [
          ...acc,
          ...flattenRoutes(choiceBranches[key], {
            sourceCode: question.code,
            shouldInclude: key.toLowerCase(),
          }),
        ];
      });
      return acc;
    },
    []
  );

export const useQuestionNavigation = (
  currentCode: string,
  KycFormAnswers: KycFormAnswers,
  onboardingData?: OnboardingSchema
) => {
  const routes = useMemo(() => {
    if (!onboardingData) return [];
    return flattenRoutes(onboardingData.branches);
  }, [onboardingData]);

  const findNextRoute = useCallback(
    (currentIndex: number): OnboardingRoute | undefined => {
      if (currentIndex === routes.length - 1) return;
      const nextRoute = routes[currentIndex + 1];
      if (!nextRoute.conditions) return nextRoute;
      const { sourceCode, shouldInclude } = nextRoute.conditions;
      const answers =
        (sourceCode in KycFormAnswers && KycFormAnswers[sourceCode]) || [];
      const conditionsMet = answers.some((answer) =>
        shouldInclude.includes(answer)
      );
      if (conditionsMet) return nextRoute;
      return findNextRoute(currentIndex + 1);
    },
    [KycFormAnswers, routes]
  );

  const currentIndex = useMemo(
    () => routes.map((r) => r.code).indexOf(currentCode),
    [currentCode, routes]
  );
  const question = currentIndex >= 0 && routes[currentIndex].question;
  const progress =
    routes.length > 0 && currentIndex > -1
      ? currentIndex / routes.length
      : currentCode === 'success'
      ? 1
      : 0;

  const getNextRoute = useCallback(() => {
    const nextRoute = findNextRoute(currentIndex);
    if (nextRoute) return nextRoute;
    return {
      code: 'success',
    };
  }, [currentIndex, findNextRoute]);

  return {
    progress,
    question,
    getNextRoute,
  };
};
