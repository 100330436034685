import { fetchAPI } from 'api';
import { KYCData, EidProviderName, Countries } from 'types';

export interface MFACodeResponse {
  mfaCode: string;
}

export interface SignInSuccessData {
  firstOnboardingCompleted: boolean;
  loginCountry: string;
  kyc: KYCData;
  requestConsent: boolean;
  tokenTtlInSeconds: number;
}

export const initiatePasswordLogin = async (
  deviceId: string,
  email: string,
  password: string,
  rememberMe: boolean
) => {
  return fetchAPI<
    {
      mfaToken?: string;
      methods?: { type: string; channels: string[]; phoneNumber: string }[];
    } & SignInSuccessData
  >('auth', {
    method: 'POST',
    body: {
      grantType: 'PASSWORD',
      deviceInstallId: deviceId,
      email,
      password,
      rememberMe,
    },
  });
};

export const initiateEIDLogin = async ({
  deviceId,
  nationality,
  userId,
  redirectUrl,
  rememberMe,
  providerName,
}: {
  deviceId: string;
  nationality: string;
  providerName?: EidProviderName;
  userId?: string;
  redirectUrl?: string;
  rememberMe: boolean;
}) => {
  const {
    eidToken: token,
    providerInfo: { url },
  } = await fetchAPI<{
    eidToken: string;
    providerInfo: { url: string };
  }>(`auth`, {
    method: 'POST',
    body: {
      grantType: 'EID',
      deviceInstallId: deviceId,
      provider: {
        providerName,
        flow: 'redirect',
      },
      nationality,
      userId,
      redirectUrl,
      rememberMe,
    },
  });
  return { token, url };
};

export const loginStatus = async (
  eidToken: string
): Promise<SignInSuccessData> => {
  return fetchAPI<SignInSuccessData>(`eid/completion`, {
    method: 'POST',
    body: {
      eidToken,
    },
  });
};

export const getAppCountries = () => {
  return fetchAPI<Countries>('countries');
};

export const initiateMFA = (mfaToken: string) => {
  return fetchAPI<MFACodeResponse | undefined>('mfa/challenge/phone', {
    method: 'POST',
    body: {
      channel: 'SMS',
      mfaToken,
    },
  });
};

export const completeMFA = (
  code: string,
  mfaToken: string,
  deviceId: string
) => {
  return fetchAPI<SignInSuccessData>('mfa/challenge-completion', {
    method: 'POST',
    body: {
      type: 'PHONE',
      deviceInstallId: deviceId,
      code,
      mfaToken,
    },
  });
};

export const resetPassword = (emailAddress: string) => {
  return fetchAPI('auth/password/reset', {
    method: 'POST',
    body: {
      emailAddress,
    },
  });
};
