import { type Currency } from '@quickbit/qb-design-system';
import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { useMount, useSessionStorage } from 'react-use';
import { Wallet } from 'types';

const SESSION_STORAGE_KEY = 'qb_web_Wallets';

interface Wallets {
  wallets: Wallet[];
}

type WalletsContext =
  | (Wallets & {
      setWallets: (data: Wallets) => void;
    })
  | {
      setWallets: (data: Wallets) => void;
    };

const Context = createContext<WalletsContext>({
  setWallets: () => undefined,
});

export const WalletsProvider = ({ children }: { children: ReactNode }) => {
  const [sessionStorageWallets, setSessionStorageWallets] =
    useSessionStorage<Wallets>(SESSION_STORAGE_KEY);
  const [wallets, setWallets] = useState<Wallets>(sessionStorageWallets);

  useMount(() => {
    if (sessionStorageWallets) {
      setWallets(sessionStorageWallets);
    }
  });

  const updateWallets = useCallback(
    (data: Wallets) => {
      setSessionStorageWallets(data);
      setWallets((prevState) => ({ ...prevState, ...data }));
    },
    [setSessionStorageWallets]
  );

  const value = useMemo(
    () => ({
      ...(wallets ? { ...wallets } : {}),
      setWallets: updateWallets,
    }),
    [wallets, updateWallets]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useWallets = (): Wallet[] => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setWallets, ...rest } = useContext(Context);

  if (!('wallets' in rest)) {
    throw Error('Using wallets context before wallets are loaded.');
  }

  return rest.wallets;
};

export const useNullableWallets = (): Wallet[] | null => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setWallets, ...rest } = useContext(Context);

  if (!('wallets' in rest)) {
    return null;
  }

  return rest.wallets;
};

export const useSetWallets = (): ((data: Wallets) => void) =>
  useContext(Context).setWallets;

export const useSelectedWallet = (currencyCode: Currency): Wallet => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setWallets, ...rest } = useContext(Context);

  if (!('wallets' in rest)) {
    throw Error('Using wallets context before wallets are loaded.');
  }

  const selectedWallet = rest.wallets.find(
    (wallet) => wallet.currencyCode === currencyCode
  );

  if (!selectedWallet) {
    throw Error('No matching wallet found');
  }

  return selectedWallet;
};
