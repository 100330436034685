/** @jsxImportSource @emotion/react */

import { Checkbox, ListItem } from '@quickbit/qb-design-system';
import { useIntl } from 'react-intl';

interface Props {
  rememberMe: boolean;
  onChange: (value: boolean) => void;
}

export const RememberMe = ({ rememberMe, onChange }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <ListItem
      icon={<Checkbox checked={rememberMe} onChange={onChange} />}
      subtitle={formatMessage({ id: 'page.login.footer.rememberMe' })}
    />
  );
};
