import { Currency } from '@quickbit/qb-design-system';

export const SUPPORTED_CRYPTO_CURRENCIES = [
  'BTC',
  'ETH',
  'USDT',
  'ADA',
  'LTC',
  'BCH',
  'LINK',
  'DOT',
  'ALGO',
];

export const SUPPORTED_SEND_CURRENCIES: Currency[] = [
  'BTC',
  'BCH',
  'LTC',
  'ETH',
];

export const DEEPLINK_STORAGE_KEY = 'qb_web_deepLink';
export const KYC_COOKIE_NAME = 'qb_web_kyc';
export const QB_PREFERENCES_STORAGE_KEY = 'qb_web_preferences';
