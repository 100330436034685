import { Typography, useTheme } from '@quickbit/qb-design-system';
import { FormattedMessage } from 'react-intl';

interface Props {
  id: string;
}

export const BodyText = ({ id }: Props) => {
  const { spacing } = useTheme();

  return (
    <div style={{ textAlign: 'center', paddingTop: spacing.s }}>
      <div style={{ marginTop: spacing.xxxs }}>
        <Typography variant="body">
          <FormattedMessage id={id} />
        </Typography>
      </div>
    </div>
  );
};
