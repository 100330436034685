import {
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
  SetStateAction,
} from 'react';

/**
 * React-uses' useLocalStorage hook had a bug where the setter function would
 * not depend on the current state. This is a fixed version of that hook.
 */
export const useLocalStorage = <T>(
  key: string,
  initialValue: T | undefined
): [
  T | undefined,
  (valOrFunc: SetStateAction<T | undefined>) => void,
  () => void
] => {
  if (!key) {
    throw new Error('useLocalStorage key may not be falsy');
  }
  const initializer = useRef((key: string): T | undefined => {
    try {
      const localStorageValue = localStorage.getItem(key);
      if (localStorageValue !== null) {
        return JSON.parse(localStorageValue);
      } else {
        initialValue && localStorage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      }
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  const [state, setState] = useState<T | undefined>(initializer.current(key));

  useLayoutEffect(() => {
    return setState(initializer.current(key));
  }, [key]);

  const set = useCallback(
    (valOrFunc: SetStateAction<T | undefined>) => {
      try {
        setState((prev) => {
          const newState =
            typeof valOrFunc === 'function'
              ? (valOrFunc as (d: T | undefined) => T | undefined)(prev)
              : valOrFunc;
          if (typeof newState === 'undefined') return;
          const value = JSON.stringify(newState);
          localStorage.setItem(key, value);
          return newState;
        });
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also JSON.stringify can throw.
      }
    },
    [key, setState]
  );
  const remove = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key, setState]);
  return [state, set, remove];
};
