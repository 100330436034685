/** @jsxImportSource @emotion/react */
import {
  Input,
  OTPInput,
  Snackbar,
  Typography,
  UtilityButton,
  createStyles,
} from '@quickbit/qb-design-system';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { ErrorComponent, Layout, LayoutModal, QBLoader } from 'components';
import { RememberMe } from 'components/login';
import { useMixpanel } from 'context';
import { useLoginCountry } from 'hooks';
import { usePasswordLogin } from 'hooks/login/usePasswordLogin';
import { useTopUpData } from './context';
import { useHandleSuccessSignIn } from './hooks/useHandleSuccessSignIn';

export const EmailLogin = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const styles = useStyles();
  const handleSuccessSignIn = useHandleSuccessSignIn();
  const { email: paramEmail, merchantName, fiatAmount } = useTopUpData();
  const { loginCountry } = useLoginCountry();
  const { mixpanelTrackEvent } = useMixpanel({
    Product: 'First Topup',
    Page: 'Login',
  });

  const [
    {
      invalidEmail,
      login,
      loading,
      error,
      email,
      setEmail,
      password,
      setPassword,
      rememberMe,
      setRememberMe,
    },
    {
      requiresMFA,
      otp,
      verifyCode,
      setOtp,
      sendCode,
      phoneNumber,
      verifyLoading,
      verifyError,
      initiateMFALoading,
      invalidOtp,
    },
  ] = usePasswordLogin({
    success: handleSuccessSignIn,
    initialEmail: paramEmail,
  });

  const onLogin = useCallback(() => {
    mixpanelTrackEvent('Login Started', {
      loginCountry,
    });

    return login();
  }, [login, loginCountry, mixpanelTrackEvent]);

  return (
    <LayoutModal onClose={() => navigate('/top-up/login')}>
      {error && (
        <ErrorComponent error={error} tryAgainAction={() => navigate(0)} />
      )}
      {initiateMFALoading && <QBLoader />}
      {!error && !initiateMFALoading && (
        <Layout
          primaryButtonProps={{
            text: formatMessage({
              id: requiresMFA ? 'continue' : 'page.login.onfido.email.heading',
            }),
            onClick: requiresMFA ? verifyCode : onLogin,
            loading: requiresMFA ? verifyLoading : loading,
            disabled: requiresMFA
              ? !otp.join('').length || invalidOtp
              : !email.length || invalidEmail || !password.length,
          }}
          title={formatMessage({
            id: requiresMFA
              ? 'page.login.2FA.title'
              : 'page.login.onfido.email.heading',
          })}
          onBack={() => navigate('/top-up/login')}
        >
          <div css={styles.main}>
            {requiresMFA && (
              <>
                <div css={styles.body}>
                  <Typography variant="bodyBold">
                    <FormattedMessage id="page.login.2FA.body.heading" />
                  </Typography>
                  <Typography variant="body">
                    {formatMessage(
                      {
                        id: 'page.login.2FA.body.subHeading',
                      },
                      {
                        phoneNumber,
                      }
                    )}
                  </Typography>
                  <UtilityButton
                    variant="text"
                    text="Send again"
                    onClick={sendCode}
                  />
                  <OTPInput
                    value={otp}
                    onChange={(code) => setOtp(code)}
                    error={verifyError != undefined}
                    errorText={formatMessage({
                      id: 'page.login.2FA.otpField.errorHelperText',
                    })}
                    onComplete={verifyCode}
                  />
                </div>
              </>
            )}
            {!requiresMFA && (
              <>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={formatMessage({
                    id: 'page.inputemail.inputLabel',
                  })}
                  error={email.length !== 0 && invalidEmail}
                  helperText={
                    email.length !== 0 && invalidEmail
                      ? 'Please enter a valid email'
                      : ''
                  }
                />
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  onKeyDown={(e) => {
                    e.key === 'Enter' && onLogin();
                  }}
                />
                <RememberMe
                  rememberMe={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <UtilityButton
                  text={formatMessage({ id: 'login.forgotPassword' })}
                  variant="text"
                  onClick={() =>
                    navigate('/top-up/login/forgot-password', {
                      state: { email },
                    })
                  }
                />
                {merchantName && (
                  <Snackbar
                    showAsBanner
                    message={formatMessage(
                      { id: 'page.topUp.login.infoBanner' },
                      { merchantName, currency: fiatAmount?.currencyCode }
                    )}
                    variant="primary"
                  />
                )}
              </>
            )}
          </div>
        </Layout>
      )}
    </LayoutModal>
  );
};

const useStyles = createStyles(({ spacing, breakpoints, sizing }) => ({
  main: {
    display: 'grid',
    gap: spacing.xxs,
    marginTop: spacing.xs,
    marginBottom: spacing.xxs,
    [breakpoints.laptopSmall]: { minWidth: sizing.small },
  },
  body: {
    display: 'grid',
    gap: spacing.xxs,
  },
}));
