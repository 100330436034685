export enum ChoiceType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface KycFormAnswers {
  [code: string]: string[];
}

export interface KycDispatchAction {
  type: 'UPDATE' | 'DELETE';
  code: string;
  value: string | string[];
}

type KYCActionStatus = 'NOT_PROVIDED' | 'UNVERIFIED' | 'REJECTED' | 'APPROVED';

type Action = { name: ActionKey; isRequired: boolean; status: KYCActionStatus };

export interface KYCData {
  accountStatus: string;
  riskLevel: string;
  actions: Action[];
  firstName: string;
  lastName: string;
  email?: { address: string; verified: boolean };
  phone?: { number: string; verified: boolean };
  address?: {
    streetLine1: string;
    city: string;
    postalCode: string;
    countryCode: string;
  };
  loginCountry: string;
}

export type ActionKey =
  | 'EMAIL'
  | 'PHONE'
  | 'ONBOARDING'
  | 'ADDRESS'
  | 'SOURCE_OF_FUNDS'
  | 'PASSWORD';
