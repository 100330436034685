import { fetchAPI } from 'api';
import { MoneyScaled } from 'types';

export interface BuyPreviewResponse {
  id: string;
  createdAt: Date;
  amount: MoneyScaled;
  amountReference: MoneyScaled;
  exchangeRateReference: MoneyScaled;
  serviceFee: MoneyScaled;
  totalReference: MoneyScaled;
  walletId: string;
  recurringFrequency: string;
  overridesRecurringOrder: boolean;
  destinationAmount?: MoneyScaled;
  sourceAmount?: MoneyScaled;
  sourceToDestinationExchangeRate?: MoneyScaled;
}

export const createBuyPreview = (receiveOrderPreviewId: string) => {
  return fetchAPI<BuyPreviewResponse>('buy/previews', {
    method: 'POST',
    body: {
      receiveOrderPreviewId,
    },
  });
};

export interface RedirectData {
  container: 'window' | 'iframe' | 'redirect';
  method?: 'POST' | 'GET';
  parameters?: object; // Exists only if method is POST
  url?: string;
  html?: string;
  width?: number;
  height?: number;
}
interface BuyOrdersResponse {
  redirect?: RedirectData;
}

export const createBuyOrder = (previewId: string) => {
  return fetchAPI<BuyOrdersResponse>(`buy/orders`, {
    method: 'POST',
    body: {
      previewId,
    },
  });
};

export interface BuyOrderStatus {
  status: string;
  sourceTransactionId?: string;
  transactionId: string;
}

export const buyOrderStatus = async (previewId: string) => {
  return await fetchAPI<BuyOrderStatus>(`buy-status/${previewId}`);
};
